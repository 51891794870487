import { BaseFileService } from '../api'

export const uploadSheet = (file: File, Uid: string, access: string, client: string) => {
  return BaseFileService.post(
    `/institution_api/v1/medical_production_imports`,
    {
      import: { file: file },
    },
    {
      headers: {
        Client: client,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const CheckSendStatus = (id: string, Uid: string, access: string, client: string) => {
  return BaseFileService.get(`/institution_api/v1/medical_production_imports/${id}`, {
    headers: {
      Client: client,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}
