import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { style } from './styled'
import { useEffect } from 'react'
import { Divider } from '@mui/material'
import { colors } from '~constants/colorsPallete'
import { Money } from '~components/table/producaoTable'
import dayjs from 'dayjs'

export default function ModalProductionDetails({
  handleClose,
  data,
  open,
}: {
  data: any
  handleClose: VoidFunction
  open: boolean
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detalhes da produção médica
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, color: colors.blue, fontWeight: 500 }}>
          Geral
        </Typography>
        {/*   <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Apelido: {data?.attributes          }
        </Typography> */}
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Nome Paciente:</b> {data?.attributes?.patient}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Nome convênio:</b> {data?.attributes?.insurance_name}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 2, fontWeight: 400 }}>
          <b>Nome do prestador:</b> {data?.attributes?.doctor_name}
        </Typography>
        <Divider />

        <Typography variant="subtitle1" sx={{ mt: 2, color: colors.blue, fontWeight: 500 }}>
          Datas previstas
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Data de recebimento do convênio:</b>{' '}
          {data?.attributes?.insurance_payment_date
            ? dayjs(data?.attributes?.insurance_payment_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : 'Sem data'}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Data prevista para pagamento:</b>{' '}
          {data?.attributes?.insurance_due_date
            ? dayjs(data?.attributes?.insurance_due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : 'Sem data'}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Data de competência do repasse:</b>{' '}
          {data?.attributes?.dt_competencia_repasse
            ? dayjs(data?.attributes?.dt_competencia_repasse, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : 'Sem data'}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 2, fontWeight: 400 }}>
          <b>Data de lançamento:</b>{' '}
          {data?.attributes?.performing_date
            ? dayjs(data?.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : 'Sem data'}
        </Typography>
        <Divider />

        <Typography variant="subtitle1" sx={{ mt: 2, color: colors.blue, fontWeight: 500 }}>
          Procedimento
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Nome do procedimento:</b> {data?.attributes?.procedure_name}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Código do procedimento:</b> {data?.attributes?.procedure_code}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 2, fontWeight: 400 }}>
          <b>Número atendimento:</b> {data?.attributes?.consultation_code || '--'}
        </Typography>
        <Divider />

        <Typography variant="subtitle1" sx={{ mt: 2, color: colors.blue, fontWeight: 500 }}>
          Valores
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Valor produzido:</b> {Money({ value: data?.attributes?.amount_produced_cents })}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Valor faturado:</b> {Money({ value: data?.attributes?.amount_gross_billed_cents })}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
          <b>Valor recebido:</b> {Money({ value: data?.attributes?.amount_paid_by_insurance_cents })}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 2, fontWeight: 400 }}>
          <b>Valor Repassado:</b> {Money({ value: data?.attributes?.net_amount_cents })}
        </Typography>

        <>
          <Divider />
          <Typography variant="subtitle1" sx={{ mt: 2, color: colors.blue, fontWeight: 500 }}>
            Glosa associada
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
            <b>Motivo da glosa:</b> {data?.attributes?.disallowance?.reason || '--'}
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
            <b>Justificativa da glosa:</b> {data?.attributes?.disallowance?.observation || '--'}
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 400 }}>
            <b>Valor da glosa:</b> {Money({ value: data?.attributes?.disallowance?.amount_cents || 0 })}
          </Typography>
        </>

        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose}>Fechar</Button>
        </div>
      </Box>
    </Modal>
  )
}
