import { Box, Grid, Paper, Typography } from '@mui/material'
import { colors } from '~constants/colorsPallete'

interface RepasseProps {
  value: number
  icon: any
  title: string
  color: string
}

export default function SmallCardsHome(data: RepasseProps) {
  return (
    <Grid item xs={6} md={3}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item xs={12} lg={3} display="flex" alignItems="center">
            <Box
              sx={{
                backgroundColor: data.color,
                borderRadius: 100,
                width: 50,
                height: 50,
                alignContent: 'center',
                display: 'flex',
              }}
            >
              {data.icon}
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography variant="subtitle1" color={colors['gray.400']}>
              {data.title}
            </Typography>
            <Typography variant="h6" fontWeight={600} color={colors['gray.600']}>
              {data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
