import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { icon, checkedIcon } from '~utils/styles'
import { GetAllUsers } from '~services/Home'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { SendSlackMessage } from '~services/Slack'
import { GetConversationSubjets } from '~services/Conversations'

interface ConversationFiltersProps {
  onFilterChange: (filters: any) => void
}

const ConversationFilters: React.FC<ConversationFiltersProps> = ({ onFilterChange }) => {
  const [filterMedicOptions, setFilterMedicOptions] = useState<any[]>([])
  const [date, setDate] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [doctor, setDoctor] = useState<any>()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const selectedDoctorOption = filterMedicOptions.filter((option) => doctor?.includes(option))
  const [subjects, setSubjects] = React.useState<any>([])
  const [status, setStatus] = React.useState<string>(null)
  const [statusProducao, setStatusProducao] = React.useState<string>(null)
  const [selectedsubject, setSelectedSubject] = React.useState<any>(null)
  const [conversations, setConversations] = React.useState('')
  const setFilter = useUserStore((state) => state.setFilter)

  const clearFilters = () => {
    // Reset filter states
    setDoctor(null)
    setStatus(null)
    setConversations('')
    setDate('')
    setSelectedSubject(null)
    setFilter('')
    setStatusProducao(null)
    // Fetch data after clearing filters
    onFilterChange({
      doctor: '',
      date: '',
      status: '',
      conversations: '',
      selectedsubject: '',
      filter: '',
      statusProducao: '',
    })
  }

  useEffect(() => {
    GetConversationSubjets(Uid, access, client)
      .then((response) => {
        setSubjects(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/shared_api/v1/conversation_subjects', error, null, Uid, 'Get')
      })

    GetAllUsers(Uid, access, client)
      .then((response) => {
        setFilterMedicOptions(response.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/doctor_names?per_page=500', error, null, Uid, 'Get')
      })
  }, [Uid, access, client])

  const handleChangeDate = (event: any) => {
    const formattedDate = dayjs(event).format('YYYY/MM/DD')
    setDate(formattedDate)
  }

  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }

  const handleChangeStatusProducao = (event) => {
    setStatusProducao(event.target.value)
  }

  const handleChangeSubject = (event) => {
    setSelectedSubject(event.target.value)
  }

  return !isOpen ? (
    <Grid container direction="row" justifyContent="flex-end" mb={2}>
      <Button
        disableElevation
        variant="contained"
        endIcon={<FilterAltRoundedIcon />}
        sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Abrir Filtros
      </Button>
    </Grid>
  ) : (
    <>
      <Grid container direction="row" justifyContent="flex-end" mb={2}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={0} pb={0}>
          <Button
            disableElevation
            variant="contained"
            endIcon={<FilterAltRoundedIcon />}
            sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Fechar Filtros
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        p={3}
        mb={2}
        border={'1px solid #E0E0E0'}
        borderRadius={'3px'}
        sx={{ backgroundColor: colors['white.200'] }}
      >
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              id="demo-customized-select-native"
              value={status}
              sx={{
                backgroundColor: colors.white,
                borderRadius: 100,
                border: 0,
                '& fieldset': { border: 'none' },
              }}
              onChange={handleChangeStatus}
            >
              <MenuItem sx={{ pl: '10px' }} value={'closed'}>
                Fechadas
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'pending'}>
                Pendentes
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status da produção médica</InputLabel>
            <Select
              id="demo-customized-select-native"
              value={statusProducao}
              sx={{
                backgroundColor: colors.white,
                borderRadius: 100,
                border: 0,
                '& fieldset': { border: 'none' },
              }}
              onChange={handleChangeStatusProducao}
            >
              <MenuItem sx={{ pl: '10px' }} value={'Sem Regra'}>
                Sem Regra
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'Outra Via'}>
                Outra Via
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'Repassado'}>
                Repassado
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'A Repassar'}>
                A Repassar
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'Glosado'}>
                Glosado
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'Não Recebido'}>
                Não Recebido
              </MenuItem>
              <MenuItem sx={{ pl: '10px' }} value={'Não Faturado'}>
                Não Faturado
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Assuntos</InputLabel>
            <Select
              id="demo-customized-select-native"
              value={selectedsubject}
              sx={{
                backgroundColor: colors.white,
                borderRadius: 100,
                border: 0,
                '& fieldset': { border: 'none' },
              }}
              onChange={handleChangeSubject}
            >
              {subjects.map((subject: any) => (
                <MenuItem sx={{ pl: '10px' }} value={subject.id}>
                  {subject.attributes.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <FormControl fullWidth>
              <DatePicker
                label="Data de Abertura"
                format="DD/MM/YYYY"
                defaultValue={dayjs()}
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 100,
                  border: 0,
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setDate(dayjs(e).format('YYYY/MM/DD'))
                  handleChangeDate(dayjs(e).format('YYYY/MM/DD'))
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          {filterMedicOptions && (
            <FormControl fullWidth>
              <Autocomplete
                multiple
                limitTags={4}
                id="checkboxes-tags-demo"
                options={['Selecionar Todos', ...filterMedicOptions]} // Adicione a opção de selecionar todos
                onChange={(event, newValue) => {
                  // Verifique se a opção de Selecionar Todos foi selecionada
                  if (newValue.some((item) => item === 'Selecionar Todos')) {
                    // Se já estava selecionada e foi clicada novamente, remova todos
                    if (selectedDoctorOption.length === filterMedicOptions.length) {
                      setDoctor([])
                    } else {
                      // Senão, selecione todos
                      setDoctor(filterMedicOptions.map((item) => item))
                    }
                  } else {
                    // Manipulação para seleção normal
                    setDoctor(newValue.map((item) => item))
                  }
                }}
                value={selectedDoctorOption}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        (option === 'Selecionar Todos' && selectedDoctorOption.length === filterMedicOptions.length)
                      }
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Médicos"
                    placeholder="Selecione os médicos"
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: 100,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
          <Box display="flex" flexDirection="row" alignContent={'center'} justifyContent={'flex-end'} mt={3}>
            <Button
              disableElevation
              variant="contained"
              sx={{ backgroundColor: colors.success, borderRadius: 100, mr: 2 }}
              onClick={() => {
                onFilterChange({ doctor, date, status, conversations, selectedsubject, statusProducao })
              }}
            >
              Filtrar
            </Button>
            <Button
              disableElevation
              variant="outlined"
              sx={{ borderColor: colors.error, color: colors.error, borderRadius: 100 }}
              onClick={clearFilters}
            >
              Limpar filtros
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ConversationFilters
