import axios from 'axios'
import moment from 'moment'
import { BaseService } from '~services/api'

export const SendSlackMessage = (errorCode: string, errorText: string, params: any, userid: string, method: string) => {
  const dayjs = moment().format('DD/MM/YYYY HH:mm:ss')
  axios.post(
    'https://hooks.slack.com/services/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',
    JSON.stringify({
      text: `*Log de erro Seiwa Web ${dayjs}:* \n\n*Endpoint*: ${errorCode} \n*Mensagem de erro*: ${errorText} \n*Método*: ${method} \n*Params*: ${JSON.stringify(
        params,
      )} \n*User ID*: ${userid} \n*Ambiente*: ${BaseService.getUri()}`,
    }),
    {
      withCredentials: false,
    },
  )
}
