import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'

interface TopProcediments {
  value: number
  plano: string
}

interface MaisAtendidosProps {
  topProcediments?: any
  [x: string]: any
}

export function MaisAtendidos({ topProcediments, ...rest }: MaisAtendidosProps) {
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)

  return (
    <Card
      sx={{
        backgroundColor: colors['gray.500'],
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleDownIcon style={{ color: colors['green.light'] }} />
          <Typography variant="subtitle2" ml={2} color={colors['green.light']}>
            PLANOS - MAIORES RECEITAS
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {topProcediments?.map((item, index) => (
            <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" pt={1} width="100%">
              <Box width="60%" mt="4%">
                <Typography variant="body1" fontWeight={500} color={colors.white}>
                  {item.plano}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography color={colors.white} fontWeight={100} variant="caption">
                  Valor
                </Typography>
                <Typography color={colors.white} variant="h6" mb={2}>
                  {formatCurrency(item.value / 100)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
