import React from 'react'
import { Card, CardContent, Box, Typography } from '@mui/material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { colors } from '~constants/colorsPallete'

interface MaisRentavelCardsProps {
  description: string
  percentage: number
}

export function MaisRentavelCards({ description, percentage }: MaisRentavelCardsProps) {
  return (
    <Card
      sx={{
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
      }}
      key={Math.random()}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleDownIcon style={{ color: colors['green.default'] }} />
          <Typography variant="subtitle2" ml={2} mr={2} color={colors['green.default']}>
            MAIS RENTAVEL
          </Typography>
        </Box>

        <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5" color={colors['gray.500']} fontWeight={700}>
            {description}
          </Typography>
          <Box mt="-5%">
            <Typography variant="caption" color={colors['green.default']} textAlign="right">
              % do meu faturamento
            </Typography>
            <Typography
              variant="h5"
              color={colors['green.default']}
              textAlign="right"
              fontWeight={700}
              sx={{ textDecoration: 'underline' }}
            >
              {percentage}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
