import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Divider } from '@mui/material'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export default function ModalRating({
  handleOpen,
  handleClose,
  text,
  open,
}: {
  handleOpen: VoidFunction
  text: any[]
  handleClose: VoidFunction
  open: boolean
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Histórico de avaliações
        </Typography>
        <Box display={'flex'} alignContent={'row'} justifyContent={'space-around'}>
          <Typography id="modal-modal-description" variant="subtitle1" sx={{ mt: 2, ml: '-24px' }}>
            Avaliação
          </Typography>
          <Typography id="modal-modal-description" variant="subtitle1" sx={{ mt: 2, ml: '-24px' }}>
            Data
          </Typography>
        </Box>
        {text?.map((item) => (
          <>
            <Box display={'flex'} alignContent={'row'} justifyContent={'space-around'}>
              <Typography id="modal-modal-description" variant="subtitle2" sx={{ mt: 2 }}>
                {item?.last} estrelas
              </Typography>
              <Typography id="modal-modal-description" variant="subtitle2" sx={{ mt: 2 }}>
                {item?.date}
              </Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
          </>
        ))}

        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose}>Fechar</Button>
        </div>
      </Box>
    </Modal>
  )
}
