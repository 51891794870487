import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'

interface TopProcediments {
  value: string
  paciente: string
}

interface PacientesMaisAtendidosProps {
  topProcediments?: any
  [x: string]: any
}

export function PacientesMaisAtendidos({ topProcediments, ...rest }: PacientesMaisAtendidosProps) {
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)

  return (
    <Card
      sx={{
        backgroundColor: 'white',
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleRightIcon style={{ color: colors['green.default'] }} />
          <Typography variant="subtitle2" ml={2} color={colors['green.default']}>
            PACIENTES - MAIORES RECEITAS
          </Typography>
        </Box>
        <Box mt={1} display="flex" flexDirection="column" alignItems="flex-end">
          {topProcediments?.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={topProcediments.length - 1 === index ? 'white' : 'grey.300'}
              pt={2}
              pb={2}
              width="100%"
            >
              <Box display="flex" flexDirection="column" width="60%" mt="4%">
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{ textTransform: 'capitalize', color: colors['gray.500'] }}
                >
                  {item.paciente}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography variant="body2" fontWeight={100} sx={{ color: colors['gray.500'] }}>
                  Valor
                </Typography>
                <Typography variant="h6" sx={{ color: colors['gray.500'] }}>
                  {formatCurrency(item.value / 100)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
