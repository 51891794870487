export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
}
