import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'

import { IconButton, TablePagination } from '@mui/material'
import { Cell, CustomTable, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import ModalCreateUser from '~components/modais/modalCreateUser'
import { DataItem, DocumentsTableProps } from './types'

export default function UsersTable(info: DocumentsTableProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [openDescription, setOpenDescription] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState<DataItem | null>(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleOpenDescription = (index) => {
    setSelectedUser(info.data[index])
    setOpenDescription(true)
  }
  const handleCloseDescription = () => setOpenDescription(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <ModalCreateUser handleClose={handleCloseDescription} open={openDescription} isEdit={true} data={selectedUser} />
      <ModalConfirm handleOpen={handleOpen} handleClose={handleClose} open={open} />
      <CustomTable>
        <Title>Todos os Usúarios</Title>
        <TableMain>
          <TableHead>
            <TableRow>
              <Cell>Nome</Cell>
              <Cell>E-mail</Cell>
              <Cell>Role</Cell>
              <Cell>Telefone</Cell>
              <Cell align="center">Editar</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {info?.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: DataItem, index: number) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row">
                    {row.attributes.first_name || 'Não informado'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.attributes.email}
                  </TableCell>
                  <TableCell align="left">
                    {row.attributes.high_level_permissions[0] === 'institution_admin'
                      ? 'Administrador'
                      : 'Usuário Comum'}
                  </TableCell>
                  <TableCell align="left">{row.attributes.phone || 'Não informado'}</TableCell>

                  <TableCell align="center">
                    <IconButton aria-label="delete" onClick={() => handleOpenDescription(index)}>
                      <EditIcon fontSize="small" sx={{ color: '#E4BE34' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TableMain>
        <TablePagination
          labelRowsPerPage="Itens por página"
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={info.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTable>
    </>
  )
}
