import { Box, Grid } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { ToastContainer, toast } from 'react-toastify'
import PushNotificationsTable from '~components/table/pushNotificationTable'
import { CustomButton } from '~utils/styles'
import AddIcon from '@mui/icons-material/Add'
import { CSSProperties, useEffect, useState } from 'react'
import ModalCreatePush from '~components/modais/modalCreatePush'
import { useUserStore } from 'stores/users'
import MiniDrawer from '~components/drawer'
import { colors } from '~constants/colorsPallete'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import { GetUsers } from '~services/User'
import { GetAllPushNotifications } from '~services/PushNotifications'
import { useLocation, useNavigate } from 'react-router-dom'

export const override: CSSProperties = {
  display: 'block',
  margin: 'auto auto',
}

export const overrideTop: CSSProperties = {
  display: 'block',
  margin: 'auto',
  marginLeft: '10px',
}

function PushNotifications() {
  const [loading, setLoading] = useState(false)
  const { openNotification, setOpenNotification } = useUserStore()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [data, setData] = useState<[]>([])
  const { openDrawer } = useUserStore()
  const [count, setCount] = useState<number>(0)
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)

  const [openNewComunicacao, setOpenNewComunicacao] = useState(false)
  const [filterMedicOptions, setFilterMedicOptions] = useState<any>([])

  useEffect(() => {
    if (openNotification) {
      setOpenNewComunicacao(true)
      setOpenNotification(false)
    }
  }, [openNotification])

  const handleFilterChange = (pageInfo: { page: string; per_page: string }) => {
    setLoading(true)
    Promise.all([
      GetUsers(Uid, access, client)
        .then((response) => {
          setFilterMedicOptions(response.data.data)
        })
        .catch((error) => {
          console.log(error)
        }),

      GetAllPushNotifications(Uid, access, client, pageInfo)
        .then((response) => {
          setCount(response.data.meta.count)
          setData(response.data.data)
        })
        .catch((error) => {
          toast.error('Ooops! Algo deu errado, tente novamente!')
          if (error.request.status === 401) {
            loggedIn(false)
            clearUser()
            navigate(from, { replace: true })
          }
          SendSlackMessage(
            `/institution_api/v1/push_notifications/?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
            error,
            null,
            Uid,
            'Get',
          )
        }),
    ])
      .then((e) => {
        console.log(e)
        setLoading(false)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/users', error, null, Uid, 'Get')
        console.log(error)
      })
  }

  return (
    <>
      <ModalCreatePush
        handleClose={() => setOpenNewComunicacao(false)}
        open={openNewComunicacao}
        doctors={filterMedicOptions}
      />
      <Box sx={{ backgroundColor: '#FAFAFA' }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header
              text="Push Notifications"
              icon={<NotificationsIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />}
            />
            <Grid item xs={16} display={'flex'} alignContent={'flex-end'} justifyContent={'flex-end'} mt={4}>
              <CustomButton onClick={() => setOpenNewComunicacao(true)} startIcon={<AddIcon />}>
                Enviar Push Notification
              </CustomButton>
            </Grid>
            <PushNotificationsTable
              data={data}
              loading={loading}
              dasy={false}
              doctorsList={filterMedicOptions}
              onFilterChange={handleFilterChange}
              count={count}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default PushNotifications
