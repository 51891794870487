import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'

interface TopProcediments {
  value: string
  plano: string
  total: string
}

interface MaisRealizadosProps {
  topProcediments?: any
  [x: string]: any
}

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)

export function MaisRealizados({ topProcediments, ...rest }: MaisRealizadosProps) {
  return (
    <Card
      sx={{
        backgroundColor: 'white',
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleRightIcon style={{ color: colors['green.default'] }} />
          <Typography variant="subtitle2" ml={2} color={colors['green.default']}>
            PROCEDIMENTOS - MAIORES RECEITAS
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {topProcediments?.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={topProcediments.length - 1 === index ? 'white' : 'grey.300'}
              pt={1}
              pb={1}
              width="100%"
            >
              <Box width="40%" mt="4%">
                <Typography variant="body1" fontWeight={500} color={colors['gray.500']}>
                  {item.plano}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography color={colors['gray.500']} fontWeight={100} variant="caption">
                  Valor
                </Typography>
                <Typography color={colors['gray.500']} variant="h6" mb={2}>
                  {formatCurrency(item.total / 100)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
