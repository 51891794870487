import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { BoxRow, Cell, ChipAlert, ChipSucess, CustomTable, Date, DateHour, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import ModalGroupDetails from '~components/modais/modalGroupDetails'
import moment from 'moment'

export default function ReducedeTable(data: any, dasy: boolean) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [openGroup, setOpenGroup] = React.useState(false)
  const [group, setGroup] = React.useState<string[]>()
  const [deleteId, setDeleteId] = React.useState<string>()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenGroup = () => setOpenGroup(true)
  const handleCloseGroup = () => setOpenGroup(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <ModalGroupDetails handleOpen={handleOpenGroup} handleClose={handleCloseGroup} open={openGroup} text={group} />
      <ModalConfirm
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        typeDelete="/institution_api/v1/push_notifications/"
        id={deleteId}
      />
      <CustomTable>
        <TableMain>
          <TableHead>
            <TableRow>
              <Cell>Título</Cell>
              <Cell>Destinatários</Cell>
              <Cell>Data</Cell>
              <Cell>Status</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data
              .slice(page * rowsPerPage, page * rowsPerPage + Math.min(rowsPerPage, 5))
              .map((row: any, index: number) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row">
                    {row.attributes.title}
                  </TableCell>
                  {row.attributes.users.length > 1 ? (
                    <TableCell
                      align="left"
                      onClick={() => {
                        setGroup(row.attributes.users)
                        handleOpenGroup()
                      }}
                    >
                      Diversos
                    </TableCell>
                  ) : (
                    <TableCell align="left">{row.attributes.users[0]?.name}</TableCell>
                  )}

                  <TableCell align="left">
                    <BoxRow>
                      <Date>{moment(row.attributes.schedule_time).format('DD/MM/YYYY')}</Date>
                      <DateHour>{moment(row.attributes.schedule_time).format('h:mm:ss a')}</DateHour>
                    </BoxRow>
                  </TableCell>
                  {dasy !== true ? (
                    <TableCell align="left">
                      {row.attributes.schedule_status === 'scheduled' ? (
                        <ChipAlert size="small" label={'PLANEJADO'} />
                      ) : (
                        <ChipSucess size="small" label={'ENVIADO'} />
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
          </TableBody>
        </TableMain>
      </CustomTable>
    </>
  )
}
