import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { style } from './styled'

export default function ModalGroupDetails({
  handleOpen,
  handleClose,
  text,
  open,
}: {
  handleOpen: VoidFunction
  text: [] | string[]
  handleClose: VoidFunction
  open: boolean
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Todos os Destinatários
        </Typography>
        {text?.map((item) => (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {item.name}
          </Typography>
        ))}

        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose}>Fechar</Button>
        </div>
      </Box>
    </Modal>
  )
}
