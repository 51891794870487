import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'

interface TopProcediments {
  value: string
  plano: string
}

interface RepassesPlanosProps {
  isLess: boolean
  topProcediments: TopProcediments[]
  [x: string]: any
}

export function RepassesPlanos({ topProcediments, isLess, ...rest }: RepassesPlanosProps) {
  return (
    <Card
      sx={{
        backgroundColor: 'white',
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      key={random(0, 1000)}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleRightIcon style={{ color: isLess ? colors.error : colors['green.default'] }} />
          <Typography variant="subtitle2" ml={2} color={isLess ? colors.error : colors['green.default']}>
            PLANOS COM REPASSES MAIS {isLess ? 'LENTOS' : 'RÁPIDOS'}
          </Typography>
        </Box>
        <Box mt={1} display="flex" flexDirection="column" alignItems="flex-end">
          {topProcediments.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={topProcediments.length - 1 === index ? 'white' : colors['gray.100']}
              pt={3}
              width="100%"
            >
              <Box display="flex" flexDirection="column" width="60%">
                <Typography variant="body1" color="textSecondary" fontWeight={500}>
                  {item.plano}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography color={colors['gray.500']} variant="body2" mb={2}>
                  Média {item.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
