import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'

interface TopProcediments {
  percentage: string
  insurance?: string
}

interface MaisGlosamProps {
  topProcediments?: any
  [x: string]: any
}

export function MaisGlosam({ topProcediments, ...rest }: MaisGlosamProps) {
  return (
    <Card
      sx={{
        backgroundColor: 'white',
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      key={random(0, 1000)}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleRightIcon style={{ color: 'green' }} />
          <Typography variant="subtitle2" ml={2} color="green">
            PLANOS QUE MAIS GLOSAM
          </Typography>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color="textSecondary" variant="caption">
            % glosado em relação ao valor total faturado com o plano
          </Typography>
        </Box>
        <Box
          mt={2}
          sx={{
            borderTop: 1,
            width: '70%',
            borderColor: 'grey.300',
            alignSelf: 'flex-end',
          }}
        />
        <Box mt={1} display="flex" flexDirection="column" alignItems="flex-end">
          {topProcediments?.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={topProcediments.length - 1 === index ? 'white' : colors['gray.100']}
              pt={2}
              pb={2}
              width="100%"
            >
              <Box display="flex" flexDirection="column" width="60%">
                <Typography variant="body1" color="textSecondary">
                  {item.insurance.name}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography color="textSecondary" variant="h6">
                  {Math.round(parseFloat(item.percentage))}%
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
