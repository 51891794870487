import { Box, Grid } from '@mui/material'
import Header from '~components/header'
import { useEffect, useState } from 'react'
import UsersTable from '~components/table/usersTable'
import ModalCreateUser from '~components/modais/modalCreateUser'
import MiniDrawer from '~components/drawer'
import { useUserStore } from 'stores/users'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { colors } from '~constants/colorsPallete'
import { LoadingIndicator } from '~components/loading'
import { GetAllInstitutionUsers } from '~services/User'

function UserManagement() {
  const [open, setOpen] = useState(false)
  const { openDrawer } = useUserStore()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const data_source = useUserStore((state) => state.User.data_source_hashid)
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleClose = () => setOpen(false)

  useEffect(() => {
    GetAllInstitutionUsers(Uid, access, client, data_source).then((response) => {
      setData(response.data.data)
    })
  }, [])

  return (
    <>
      <ModalCreateUser handleClose={handleClose} open={open} isEdit={false} />
      <Box sx={{ backgroundColor: '#FAFAFA' }}>
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header
              text="Gerência de Usuários"
              icon={<PersonAddIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />}
            />
            {loading ? <LoadingIndicator /> : <UsersTable data={data} />}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default UserManagement
