import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useUserStore } from 'stores/users'
import { UpdateNfs } from '~services/Receipts'
import { style } from './styled'

export default function ModalSendNfs({
  id,
  handleClose,
  isValid,
  open,
}: {
  isValid: number
  id: string
  handleClose: VoidFunction
  open: boolean
}) {
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const Uid = useUserStore((state) => state.phone)

  const handleChange = () => {
    const data = {
      received_status: 0,
      verification_status: isValid === 1 ? 'refused' : 'validated',
    }
    UpdateNfs(id, Uid, access, client, data)
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detalhes
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!isValid
            ? 'Essa nota será enviada ao financeiro para pagamento, deseja continuar?'
            : 'Essa nota irá ser recusada pelo financeiro, deseja continuar?'}
        </Typography>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose} sx={{ mr: 2, color: '#4B4A64' }}>
            Fechar
          </Button>
          <Button variant="contained" onClick={handleChange} sx={{ backgroundColor: '#009D81' }}>
            Enviar
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
