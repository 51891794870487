import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Header from '~components/header'
import ModalNewComunicacao from '~components/modais/modalNewComunicacao'
import ProducaoTable from '~components/table/producaoTable'
import { BaseService } from '~services/api'
import { useUserStore } from 'stores/users'
import { LoadingIndicator } from '~components/loading'
import { colors } from '~constants/colorsPallete'
import MiniDrawer from '~components/drawer'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import SmallCardsHome from '~components/homeCards/smallCardsHome'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded'
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import ProductionFilters from './filters'
import { SendSlackMessage } from '~services/Slack'
import NoData from '~containers/noData'
import { GetMedicalProductions } from '~services/MedicalProductions'
import { Attributes } from '~components/table/producaoTable/type'
import { useLocation, useNavigate } from 'react-router-dom'

function Producao() {
  const setNotificationMessageToken = useUserStore((state) => state.setNotificationMessageToken)
  const [openNewComunicacao, setOpenNewComunicacao] = React.useState(false)
  const [filterMedicOptions, setFilterMedicOptions] = React.useState<any>([])
  const [dashboardData, setDashboardData] = useState<any>()
  const [loading, setLoading] = React.useState(false)
  const { openDrawer } = useUserStore()
  const [data, setData] = React.useState<Attributes[]>()
  const [newParams, setNewParams] = React.useState<any>({})
  const Uid = useUserStore((state) => state.phone)
  const filter = useUserStore((state) => state.filter)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [pageInfo, setPageInfo] = useState({ page: '1', per_page: '10' })
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)
  const [count, setCount] = React.useState<number>(0)
  const params: Record<string, string> = {}
  const [filters, setFilters] = useState<{
    doctor: string
    date: string
    endDate: string
    repasseDate: string
    endRepasseDate: string
    status: string
    conversations: string
    insurances: string
    procedureCodes: string
    consultationCode: string
  }>({
    doctor: '',
    date: '',
    endDate: '',
    repasseDate: '',
    endRepasseDate: '',
    status: '',
    conversations: '',
    insurances: '',
    procedureCodes: '',
    consultationCode: '',
  })

  const handleFilterChange = (newFilters: {
    doctor: string
    date: string
    endDate: string
    repasseDate: string
    endRepasseDate: string
    status: string
    conversations: string
    insurances: string
    procedureCodes: string
    consultationCode: string
  }) => {
    setFilters(newFilters)
  }

  const fetchData = (pageInfo: { per_page: string; page: string }) => {
    setLoading(true)

    if (filters.doctor) {
      params['doctor_names[]'] = filters.doctor
    }

    if (filter !== '') {
      params['conversations'] = 'true'
      params['exactly_search_keys[]'] = 'institution_status'
      params['exactly_search_values[]'] = filter
    }

    if (filters.status) {
      params['status'] = filters.status
    }

    if (filters.consultationCode) {
      params['consultation_code'] = filters.consultationCode
    }

    if (filters.insurances) {
      params['insurance_hashids[]'] = filters.insurances
    }

    if (filters.procedureCodes) {
      params['tuss_id'] = filters.procedureCodes
    }

    if (filters.conversations) {
      params['conversations'] = filters.conversations
    }

    if (filters.date) {
      params['start_date'] = filters.date
    }

    if (filters.repasseDate) {
      params['start_date'] = filters.repasseDate
      params['dt_competencia_repasse_start_date'] = filters.repasseDate
    }

    if (filters.endRepasseDate) {
      params['dt_competencia_repasse_end_date'] = filters.endRepasseDate
      params['end_date'] = filters.endRepasseDate
    }

    if (filters.endDate) {
      params['end_date'] = filters.endDate
    }

    setData([])

    GetMedicalProductions(Uid, access, client, pageInfo, params)
      .then((response) => {
        setDashboardData(response.data.meta)
        setCount(response.data.meta.count)
        setData(response.data.data)
        setNewParams(params)
        setNotificationMessageToken(null)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.request.status === 401) {
          loggedIn(false)
          clearUser()
          navigate(from, { replace: true })
        }
        toast.error('Ooops! Algo deu errado, tente novamente!')
        SendSlackMessage(
          `/institution_api/v1/medical_productions?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
          error,
          params,
          Uid,
          'Get',
        )
      })
  }

  useEffect(() => {
    fetchData(pageInfo)
  }, [filters])

  const handlePageChange = (pageInfo: { page: string; per_page: string }) => {
    fetchData(pageInfo)
  }

  return (
    <>
      <ModalNewComunicacao
        handleClose={() => setOpenNewComunicacao(false)}
        open={openNewComunicacao}
        doctors={filterMedicOptions}
      />
      <Box sx={{ backgroundColor: '#FAFAFA' }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header
              text="Produção Médica"
              icon={<ContentPasteIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />}
            />
            <ProductionFilters onFilterChange={handleFilterChange} />
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Grid container spacing={2}>
                <SmallCardsHome
                  color={colors.blue}
                  value={dashboardData?.total_amount_produced / 100}
                  icon={<BarChartRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 40, height: 40 }} />}
                  title={'Vl Total Produzido'}
                />
                <SmallCardsHome
                  color={colors.cyan}
                  value={dashboardData?.total_amount_gross_billed / 100}
                  icon={
                    <MonetizationOnRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 40, height: 40 }} />
                  }
                  title={'Vl Total Faturado'}
                />
                <SmallCardsHome
                  color={colors['green.light']}
                  value={dashboardData?.total_amount_paid_by_insurance / 100}
                  icon={<HandshakeRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 40, height: 40 }} />}
                  title={'Vl Total Recebido'}
                />
                <SmallCardsHome
                  color={colors.warn}
                  value={dashboardData?.total_net_amount / 100}
                  icon={<AddchartRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 40, height: 40 }} />}
                  title={' Vl Total Repassado'}
                />
              </Grid>
            )}

            {data?.length === 0 && !loading ? (
              <NoData />
            ) : (
              <ProducaoTable
                params={newParams}
                data={data}
                isCom={true}
                loading={loading}
                onFilterChange={handlePageChange}
                count={count}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Producao
