import * as Yup from 'yup'

export const LoginSchema = Yup.object().shape({
  uid: Yup.string().required('O E-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
})

export const validationSchema = Yup.object({
  email: Yup.string().email('O Email deve ser valido').max(255).required('O Email é obrigatório'),
  password: Yup.string().max(255).required('A Senha é obrigatória'),
})

export const NfsSchema = Yup.object().shape({
  cpf_cnpj: Yup.string().required('O CNPJ/CPF é obrigatório'),
  name: Yup.string().required('O Nome Fantasia é obrigatório'),
})

export const CompanySchema = Yup.object().shape({
  cnpj: Yup.string().required('O CNPJ é obrigatório'),
  invoiceNumber: Yup.string().required('O número da nota fiscal é obrigatório'),
  fantasia: Yup.string().required('O Nome Fantasia é obrigatório'),
})

export const CodeSchema = Yup.object().shape({
  code: Yup.string().required('O código é obrigatório'),
})

export const EnterSchema = Yup.object().shape({
  email: Yup.string().email('Digite o seu endereço de E-mail').required('O E-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
  password_confirmation: Yup.string().required('A confirmação de senha é obrigatória'),
  phone: Yup.string().required('O telefone é obrigatório'),
})
