import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, FormControl, Grid, TextField, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { checkedIcon, icon } from '~utils/styles'
import { options } from '~constants/permissions'
import { style } from './styled'
import { UpdateUser } from '~services/User'
import { useUserStore } from 'stores/users'
import { DataItem } from '~components/table/usersTable/types'

export default function ModalCreateUser({
  handleClose,
  open,
  isEdit,
  data,
}: {
  handleClose: any
  open: boolean
  isEdit: boolean
  data?: DataItem
}) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [permissions, setPermissions] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const resetValues = () => {
    setName('')
    setEmail('')
    setPhone('')
    setPermissions([])
  }

  useEffect(() => {
    if (data) {
      setName(data.attributes.first_name)
      setEmail(data.attributes.email)
      setPhone(data.attributes.phone)
      setPermissions(data.attributes.high_level_permissions)
    }
  }, [data, open])

  const sendData = () => {
    UpdateUser(Uid, access, client, phone, email, name, data.id, permissions).then(() => {
      handleClose()
      window.location.reload()
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={isMobile ? '80%' : '40%'}>
        <Typography variant="h5" component="h2">
          {isEdit ? 'Editar Usúario' : 'Cadastrar Novo Usuário'}
        </Typography>

        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
              {isEdit
                ? 'Gerencie o acesso do usúario na plataforma.'
                : 'Crie e gerencie o acesso dos usúarios na plataforma.'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} mt={4}>
            {/*   <CustomInput
              fullWidth
              placeholder="Nome"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <CustomInput
              fullWidth
              placeholder="E-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <CustomInput
              fullWidth
              placeholder="Telefone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value)
              }}
            /> */}

            <FormControl fullWidth sx={{ marginTop: 2, marginLeft: 4, paddingRight: 8 }}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                onChange={(event: any, newValue: any) => {
                  setPermissions(newValue.map((item) => item.value))
                }}
                value={permissions.map((item) => {
                  return options.find((option) => option.value === item)
                })}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.label}
                defaultValue={options.filter((option) => permissions?.includes(option.value))}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      key={option.label}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params: any) => <TextField {...params} label="Permissões" placeholder="Permissões" />}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button
            onClick={() => {
              handleClose()
              resetValues()
            }}
            sx={{ mr: 1, color: '#984A4A' }}
          >
            Cancelar
          </Button>
          <Button onClick={sendData} sx={{ mr: 3, color: '#009D81' }}>
            {isEdit ? 'Editar' : 'Cadastrar'}
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
