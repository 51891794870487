import { ApiVersion, BaseService } from '~services/api'

export const LoginUser = (data: any) => {
  return BaseService.post(`/institution_api/v1/institution_users/authenticate`, data, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      APP_VERSION: ApiVersion,
    },
  })
}

export const SignInUser = (Uid: string, access: string, client: string, data: any) => {
  return BaseService.post(
    `/institution_api/v1/institution_users`,
    {
      institution_user: data,
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const SendSms = (data: string) => {
  return BaseService.get(`/institution_api/v1/institution_users/resend_phone_confirmation?phone=${data}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',

      APP_VERSION: ApiVersion,
    },
  })
}
