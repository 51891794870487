import { Card, CardContent, Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { colors } from '~constants/colorsPallete'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

interface ExtractFullCardProps {
  data: any
}

export function LogsCard({ data }: ExtractFullCardProps) {
  console.log(data)
  return (
    <>
      {Object.entries(data[0]).map(([key, value]) => (
        <Card
          sx={{
            width: '50%',
            margin: 'auto',
            marginBottom: 2,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderRadius: 2,
            boxShadow: 6,
            backgroundColor: 'white',
            px: 1,
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <VisibilityIcon sx={{ color: colors['green.default'] }} />
                <Typography
                  variant="subtitle1"
                  fontWeight={700}
                  color={colors['gray.600']}
                  ml={2}
                  textTransform={'capitalize'}
                >
                  {dayjs(key).format('MMMM/YYYY')}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="text.primary">
                <b style={{ color: colors['blue.light'], marginRight: 6, fontSize: 24 }}>{value}</b>Acessos
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  )
}
