import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { CustomTable, TableMain } from './styled'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'
moment.locale('pt-br')

export default function NotificationCard(data: any) {
  const id = useUserStore((state) => state.id)

  return (
    <CustomTable>
      <TableMain>
        <TableBody>
          {data.data
            .slice()
            .reverse()
            .map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: row.data.attributes.sender_hashid === id ? '#24B779' : 'white',
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: '700',
                    color: row.data.attributes.sender_hashid === id ? colors.white : colors['gray.500'],
                  }}
                >
                  Mensagem {index + 1}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: row.data.attributes.sender_hashid === id ? colors.white : colors['gray.500'] }}
                >
                  {row.data.attributes.content}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: row.data.attributes.sender_hashid === id ? colors.white : colors['gray.500'] }}
                >
                  {moment(row.data.attributes.created_at).format('DD MMMM YYYY, h:mm:ss a')}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableMain>
    </CustomTable>
  )
}
