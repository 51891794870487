import { Box, Typography } from '@mui/material'
import { overrideTop } from 'pages/PushNotificationPage'
import { useState } from 'react'
import { MoonLoader } from 'react-spinners'
import ModalUploadSheet from '~components/modais/modalUploadSheet'
import { colors } from '~constants/colorsPallete'

const HeaderSheetSend = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  return (
    <>
      <ModalUploadSheet open={open} handleClose={handleClose} />
      <Box
        alignSelf={'center'}
        onClick={() => setOpen(true)}
        display={'flex'}
        bgcolor={colors['gray.100']}
        pl={2}
        width={'300px'}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
        height={60}
        alignContent={'center'}
        justifyContent={'center'}
        margin={'auto'}
      >
        <MoonLoader
          color={'#009d81'}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={overrideTop}
        />
        <Typography variant="subtitle1" component="div" textAlign={'center'} mt={2} sx={{ flexGrow: 1 }}>
          Enviando planilha de dados...
        </Typography>
      </Box>
    </>
  )
}

export default HeaderSheetSend
