import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { TableCell, TableRow } from '@mui/material'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { Cell, CustomTable, TableMain } from '~components/table/ratingTable/styled'

export default function ModalRatingHistory({
  handleClose,
  open,
  data,
  id,
}: {
  handleOpen: (data: any) => void
  handleClose: VoidFunction
  open: boolean
  data: any
  id: any
}) {
  const res = data.filter((item: any) => {
    return id?.attributes?.user_hashid === item?.attributes?.user_hashid
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '70%' : '70%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Histórico de Avaliações
        </Typography>
        <Typography id="modal-modal-title" variant="subtitle1" component="h2">
          {res[0]?.attributes.user_name}
        </Typography>

        <Box display={'flex'} alignContent={'row'} justifyContent={'space-around'}>
          <CustomTable>
            <TableMain>
              <TableHead>
                <TableRow>
                  <Cell align="center">Data</Cell>
                  <Cell align="center">Avaliação</Cell>
                  <Cell align="center">Observação</Cell>
                  <Cell align="center">Alerta</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {res.map((row: any, index: number) => (
                  <TableRow key={index} hover>
                    <TableCell align="center"> {dayjs(row.attributes.created_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center"> {row.attributes.rating}</TableCell>
                    <TableCell align="center">{row.attributes?.comments || '-'}</TableCell>
                    <TableCell align="center">
                      <Box width={'100%'} justifyContent={'center'} display={'flex'}>
                        <Box
                          height={16}
                          width={16}
                          borderRadius={100}
                          bgcolor={
                            row.attributes.rating < 6
                              ? colors.error
                              : row.attributes.rating >= 9
                              ? colors['green.default']
                              : colors.warn
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableMain>
          </CustomTable>
        </Box>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose}>Fechar</Button>
        </div>
      </Box>
    </Modal>
  )
}
