import { styled } from '@mui/material/styles'
import { purple } from '@mui/material/colors'
import { Button, ButtonProps } from '@mui/material'

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  borderRadius: '12px',

  backgroundColor: '#E96338',
  '&:hover': {
    backgroundColor: '#E96338',
  },
}))
