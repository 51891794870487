import { Grid, Paper, Typography } from '@mui/material'
import { CustomButton } from './styled'
import ModalUploadSheet from '~components/modais/modalUploadSheet'
import React from 'react'
import { colors } from '~constants/colorsPallete'

export default function ManualUpload() {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => setOpen(false)
  return (
    <>
      <ModalUploadSheet open={open} handleClose={handleClose} />
      <Grid item xs={12} md={2}>
        <Paper variant="outlined" square={false} sx={{ height: 184 }}>
          <Grid container p={2}>
            <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
              <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
                Upload Manual de Dados da Planilha
              </Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12} display={'flex'} justifyContent={'flex-end'}>
              <CustomButton
                onClick={() => {
                  setOpen(true)
                }}
              >
                Cadastrar
              </CustomButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}
