import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import HistoryIcon from '@mui/icons-material/History'

import { Box, IconButton, TablePagination } from '@mui/material'
import { Cell, TableMain, Title } from './styled'
import ModalRating from '~components/modais/modalRating'
import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import { CustomTable } from '../conversationsTable/styled'
import ModalDetail from '~components/modais/modalDetail'
import ModalRatingHistory from '~components/modais/modalRatingHistory'

export default function RatingTable(data: any, allData: any) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [item, setItem] = React.useState(null)
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false)
  const [openDetail, setOpenDetail] = React.useState(false)
  const [rowData, setRowData] = React.useState<any>([])
  const [clickedUserName, setClickedUserName] = React.useState('')

  const handleOpenConfirm = () => setOpenModalConfirm(true)
  const handleCloseConfirm = () => setOpenModalConfirm(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenHistory = (userName: string) => {
    setClickedUserName(userName)
    setOpenHistory(true)
  }
  const handleCloseHistory = () => setOpenHistory(false)
  const handleOpenDetail = () => setOpenDetail(true)
  const handleCloseDetail = () => setOpenDetail(false)
  const [openHistory, setOpenHistory] = React.useState(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <ModalRating
        handleOpen={handleOpenConfirm}
        handleClose={handleCloseConfirm}
        open={openModalConfirm}
        text={item}
      />
      <ModalRatingHistory
        handleOpen={handleOpenHistory}
        handleClose={handleCloseHistory}
        open={openHistory}
        data={data.allData}
        id={rowData}
      />
      <ModalDetail handleOpen={handleOpenDetail} handleClose={handleCloseDetail} open={openDetail} data={rowData} />
      <CustomTable>
        <Title>Todas as Avaliações</Title>
        <TableMain>
          <TableHead>
            <TableRow>
              <Cell align="center">Médico</Cell>
              <Cell align="center">Última Avaliação</Cell>
              <Cell align="center">Data da Avaliação</Cell>
              <Cell align="center">Observação</Cell>
              <Cell align="center">Histórico de Avaliações</Cell>
              <Cell align="center">Alerta</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => (
              <TableRow key={index} hover>
                <TableCell align="center" component="th" scope="row">
                  {row.attributes.user_name}
                </TableCell>

                <TableCell align="center"> {row.attributes.rating}</TableCell>
                <TableCell align="center"> {dayjs(row.attributes.created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="center"> {row.attributes?.comments || '-'}</TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setRowData(row)
                      setOpenHistory(true)
                    }}
                  >
                    <HistoryIcon fontSize="small" sx={{ color: colors.blue }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Box width={'100%'} justifyContent={'center'} display={'flex'}>
                    <Box
                      height={16}
                      width={16}
                      borderRadius={100}
                      bgcolor={
                        row.attributes.rating <= 6
                          ? colors.error
                          : row.attributes.rating >= 9
                          ? colors['green.default']
                          : colors.warn
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableMain>
        <TablePagination
          labelRowsPerPage="Itens por página"
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTable>
    </>
  )
}
