import { Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import AmountCard from '~components/homeCards/AmountCard'
import BarChartCard from '~components/homeCards/BarChartCard'
import PendentResponses from '~components/homeCards/PendentResponses'
import SatisfactionCard from '~components/homeCards/SatisfactionCard'
import BigCard from '~components/homeCards/bigCard'
import ChartCard from '~components/homeCards/chartCard'
import Repasse from '~components/homeCards/repasse'
import SmallCardsHome from '~components/homeCards/smallCardsHome'
import ReducedeTable from '~components/table/pushNotificationTable/reducedVersion'
import { colors } from '~constants/colorsPallete'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded'
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded'
import ManualUpload from '~components/homeCards/manualUpload'
import { LoadingIndicatorHeader } from '~components/loading'
import { HomeInfoProps } from './types'

const HomeInfo: React.FC<HomeInfoProps> = ({
  dashboardData,
  pendingNfs,
  loading,
  pushData,
  npsData,
  date,
  loadingHeader,
  headers,
}) => {
  return (
    <Grid container spacing={2}>
      {loadingHeader ? (
        <Grid item xs={12}>
          <LoadingIndicatorHeader />
        </Grid>
      ) : (
        <>
          <SmallCardsHome
            color={colors.blue}
            value={headers?.amounts?.total_amount_produced / 100}
            icon={<BarChartRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 30, height: 30 }} />}
            title={'Vl Total Produzido'}
          />
          <SmallCardsHome
            color={colors.cyan}
            value={headers?.amounts?.total_amount_gross_billed / 100}
            icon={<MonetizationOnRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 30, height: 30 }} />}
            title={'Vl Total Faturado'}
          />
          <SmallCardsHome
            color={colors['green.light']}
            value={headers?.amounts?.total_amount_paid_by_insurance / 100}
            icon={<HandshakeRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 30, height: 30 }} />}
            title={'Vl Total Recebido'}
          />
          <SmallCardsHome
            color={colors.warn}
            value={headers?.amounts?.total_net_amount / 100}
            icon={<AddchartRoundedIcon sx={{ margin: 'auto', color: colors.white, width: 30, height: 30 }} />}
            title={' Vl Total Repassado'}
          />
        </>
      )}
      <AmountCard
        lateValue={dashboardData?.late_transactions / 100}
        actualDate={date}
        date={dashboardData?.next_due_date}
        valueRepasse={dashboardData?.paid_value / 100}
        valuePrevisto={dashboardData?.expected_value / 100}
        barValue={(dashboardData?.paid_value / dashboardData?.total_amount_on_next_due_date) * 100}
      />
      <Repasse
        data={{
          date: dashboardData?.next_due_date,
          value: dashboardData?.total_amount_on_next_due_date / 100,
          tipo: dashboardData?.next_due_date === moment().format('YYYY-MM-DD') ? false : true,
        }}
      />
      <BigCard data={pendingNfs} />
      <ManualUpload />
      <ChartCard data={dashboardData?.doctor_conversation_stats} date={date} isLine={false} />
      <SatisfactionCard data={npsData} />
      <BarChartCard
        data2={headers?.amounts?.total_amount_gross_billed / 100}
        dataTitle="Valor Total Produzido"
        data2Title="Valor Total Faturado"
        data3Title="Valor Total Recebido"
        data3={headers?.amounts?.total_amount_paid_by_insurance / 100}
        data={headers?.amounts.total_amount_produced / 100}
        title={'Produzido x Faturado x Recebido'}
        month={date}
      />
      {/*       <ChartCard data={dashboardData?.doctor_conversation_stats} date={date} isLine /> */}
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" square={false}>
          <Grid container p={2}>
            <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
              <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
                Últimos Push Notifications Enviados
              </Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              {!loading ? <ReducedeTable data={pushData} dasy={true} /> : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <PendentResponses data={dashboardData?.conversation_by_institution_status} />
    </Grid>
  )
}

export default HomeInfo
