import { InputBase, styled } from '@mui/material'

export const CustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '12px',
    marginTop: '10px',
    marginLeft: '34px',
    backgroundColor: '#E3E2EF',
    marginRight: '34px',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}
