import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'

import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import { ToastContainer, toast } from 'react-toastify'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'
import { LoginUser } from '~services/Auth'
import { LoginSchema } from 'schemas'

const easing = [0.6, -0.05, 0.01, 0.99]
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
}

const LoginForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const from = location.state?.from?.pathname || '/'
  const [showPassword, setShowPassword] = useState(false)
  const setIsLogged = useUserStore((state) => state.setLogged)

  const setId = useUserStore((state) => state.setId)
  const setType = useUserStore((state) => state.setType)
  const setUser = useUserStore((state) => state.setUser)
  const setToken = useUserStore((state) => state.setToken)
  const setPhone = useUserStore((state) => state.setPhone)
  const setClient = useUserStore((state) => state.setClient)

  const formik = useFormik({
    initialValues: {
      uid: '',
      password: '',
    },
    validationSchema: LoginSchema,

    onSubmit: () => {
      LoginUser(formik.values)
        .then((res) => {
          setUser(res.data.data.attributes)
          setId(res.data.data.id)
          setType(res.data.data.type)
          setToken(res.headers['access-token'])
          setClient(res.headers.client)
          setPhone(res.headers.uid)
          setIsLogged(true)
          navigate(from, { replace: true })
        })
        .catch((err) => {
          setSubmitting(false)
          if (err.response.data.errors[0] === 'Não há uma conta com o número de telefone inserido.') {
            toast.error('Usuário não encontrado. Tente novamente!')
          } else {
            toast.error(err.response.data.errors[0])
          }
        })
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            component={motion.div}
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <TextField
                fullWidth
                autoComplete="username"
                type="text"
                label="E-mail"
                {...getFieldProps('uid')}
                error={Boolean(touched.uid && errors.uid)}
                helperText={touched.uid && errors.uid}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <Icon icon="eva:eye-fill" /> : <Icon icon="eva:eye-off-fill" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disableElevation
                sx={{ backgroundColor: colors['green.default'] }}
              >
                {isSubmitting ? 'Carregando...' : 'Entrar'}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </>
  )
}

export default LoginForm
