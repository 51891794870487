import React, { useState } from 'react'
import { Card, CardContent, Box, Typography, Tooltip, IconButton } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import InfoIcon from '@mui/icons-material/Info'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import dayjs, { Dayjs } from 'dayjs'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { colors } from '~constants/colorsPallete'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

interface ExtractFullCardProps {
  label: string
  icon: string
  value: number
  date?: Dayjs
  onPress: () => void
  hide?: boolean
  [x: string]: any
}

export function ExtractFullCard({ label, icon, value, date, onPress, hide, ...rest }: ExtractFullCardProps) {
  const user = { attributes: { phone: '(31) 9 9999-5555', discountRate: 0 } } // Replace with actual user context
  const isHospital = true // Replace with actual condition

  const [showTooltip, setShowTooltip] = useState(false)
  const [showTooltip2, setShowTooltip2] = useState(false)

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)

  const renderContent = () => {
    if (isHospital && user.attributes.discountRate > 0) {
      return !hide && <Typography variant="h3">{formatCurrency(value)}</Typography>
    }
    if (
      isHospital &&
      label === 'A REPASSAR' &&
      user.attributes.discountRate === 0 &&
      user.attributes.phone !== '(31) 9 9999-5555'
    ) {
      return (
        <Tooltip
          title="Sem dados suficientes para cálculo dos valores a repassar."
          open={showTooltip2}
          onClose={() => setShowTooltip2(false)}
          onOpen={() => setShowTooltip2(true)}
        >
          <IconButton color="inherit">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      )
    }
    if (label === 'A REPASSAR' && user.attributes.phone === '(31) 9 9999-5555') {
      return (
        !hide && (
          <Box width="100%" justifyContent={'space-between'} pt={1} display="flex" flexDirection="row">
            <Box>
              <Typography fontWeight={500} color={colors['gray.500']}>
                {dayjs(date).format('MMM')}
              </Typography>
              <Typography fontWeight={300} color={colors['gray.500']}>
                Próximo Mês
              </Typography>
            </Box>

            <Box>
              <Typography variant="h4" fontWeight={500} color={colors['gray.600']}>
                {formatCurrency(value / 100)}
              </Typography>
            </Box>
          </Box>
        )
      )
    }
    if (label === 'REPASSADO' && user.attributes.phone === '(31) 9 9999-5555') {
      return (
        !hide && (
          <Box width="100%" justifyContent={'space-between'} pt={1} display="flex" flexDirection="row">
            <Box>
              <Typography fontWeight={500} color={colors['gray.500']}>
                {dayjs(date).format('MMM')}
              </Typography>
              <Typography fontWeight={300} color={colors['gray.500']}>
                Mês passado
              </Typography>
            </Box>

            <Box>
              <Typography variant="h4" fontWeight={500} color={colors['gray.600']}>
                {formatCurrency(value / 100)}
              </Typography>
            </Box>
          </Box>
        )
      )
    }

    if (label === 'GLOSAS') {
      return (
        !hide && (
          <Box width="100%" justifyContent={'space-between'} pt={1} display="flex" flexDirection="row">
            <Box>
              <Typography fontWeight={500} color={colors['gray.500']}>
                {dayjs(date).format('MMM')}
              </Typography>
              <Typography fontWeight={300} color={colors['gray.500']}>
                Mês atual
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontWeight={500} color={colors['gray.600']}>
                {formatCurrency(value / 100)}
              </Typography>
            </Box>
          </Box>
        )
      )
    }
  }

  return (
    <Card
      sx={{
        width: '50%',
        margin: 'auto',
        marginBottom: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 2,
        boxShadow: 6,
        backgroundColor: 'white',
        p: 1,
        ...rest.sx,
      }}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'space-between'}>
          <Box display="flex" flexDirection="row">
            {icon === 'warning' ? (
              <WarningRoundedIcon style={{ color: colors['gray.300'] }} />
            ) : (
              <ArrowCircleRightIcon style={{ color: colors['gray.300'] }} />
            )}

            <Typography variant="subtitle1" fontWeight={700} ml={2} color={colors['gray.300']}>
              {label}
            </Typography>
          </Box>

          {label === 'A REPASSAR' && (
            <Tooltip
              title="Valor estimado baseado no histórico da relação entre repasse realizado e valor total do item na conta."
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
              onOpen={() => setShowTooltip(true)}
            >
              <IconButton sx={{ color: colors['gray.300'] }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </CardContent>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {renderContent()}
        </Box>
      </CardContent>
    </Card>
  )
}
