import { useUserStore } from 'stores/users'

function CheckAccess(route) {
  const User = useUserStore()

  if (
    User.User.high_level_permissions.includes('institution_admin') ||
    User.User.high_level_permissions?.includes(route.replace('/', ''))
  ) {
    return true
  } else {
    return false
  }
}
export default CheckAccess
