import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import CheckConfirm from '~assets/images/checkConfirm.png'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export default function ModalConfirmDoc({
  handleOpen,
  handleClose,
  open,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  open: boolean
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={4}>
          Documento recebido com Sucesso!
        </Typography>
        <img src={CheckConfirm} width={100} height={100} />
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose}>Fechar</Button>
        </div>
      </Box>
    </Modal>
  )
}
