import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { Box, IconButton, TablePagination } from '@mui/material'
import { Cell, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import ModalDetail from '~components/modais/modalDetail'
import ModalEditBusiness from '~components/modais/modalEditBusiness'
import { CustomTable } from '../conversationsTable/styled'
import { MoonLoader } from 'react-spinners'
import { override } from 'pages/PushNotificationPage'
import NoData from '~containers/noData'

interface DoctorTableProps {
  data: any
  loading: boolean
  onFilterChange: (filters: any) => void
  count: number
}

export default function AllDoctorsTable({ data, loading, onFilterChange, count }: DoctorTableProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [rowData, setRowData] = React.useState<any>([])
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openDetail, setOpenDetail] = React.useState(false)
  const handleOpenDetail = () => setOpenDetail(true)
  const handleCloseDetail = () => setOpenDetail(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  React.useEffect(() => {
    onFilterChange({ page: page + 1, per_page: rowsPerPage })
  }, [page, rowsPerPage])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      {loading ? (
        <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
          <MoonLoader
            color={'#009d81'}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
          />
        </Box>
      ) : data?.length === 0 ? (
        <NoData />
      ) : (
        data && (
          <CustomTable>
            <Title>Todas as Empresas</Title>
            <TableMain>
              <TableHead>
                <TableRow>
                  <Cell align="center">Razão social</Cell>
                  <Cell align="center">CNPJ</Cell>
                  <Cell align="center">Email responsável</Cell>
                  <Cell align="center">Detalhes</Cell>
                  <Cell align="center">Editar</Cell>
                  <Cell align="center">Deletar</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row" id={labelId} align="center">
                        {row.attributes?.name}
                      </TableCell>
                      <TableCell align="center">{row.attributes?.cpf_cnpj}</TableCell>
                      <TableCell align="center">{row.attributes?.email}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setRowData(row)
                            handleOpenDetail()
                          }}
                        >
                          <VisibilityIcon fontSize="small" sx={{ color: '#009d81' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setRowData(row)
                            setOpenEdit(true)
                          }}
                        >
                          <EditIcon fontSize="small" sx={{ color: '#E4BE34' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setRowData(row)
                            handleOpen()
                          }}
                        >
                          <DeleteIcon fontSize="small" sx={{ color: '#E96338' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableMain>
            <TablePagination
              labelRowsPerPage="Itens por página"
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CustomTable>
        )
      )}
      <ModalEditBusiness handleClose={() => setOpenEdit(false)} open={openEdit} data={rowData} id={rowData.id} />
      <ModalConfirm
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        typeDelete="/institution_api/v1/companies/"
        id={rowData.id}
      />
      <ModalDetail handleOpen={handleOpenDetail} handleClose={handleCloseDetail} open={openDetail} data={rowData} />
    </>
  )
}
