import React, { useEffect, useState } from 'react'
import { Card, CardContent, Box, Typography, IconButton, Tooltip } from '@mui/material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import InfoIcon from '@mui/icons-material/Info'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { random } from 'lodash'
import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')
interface ExtractSummaryProps {
  receivableCurrentCents?: number
  receivedCurrentCents?: number
  receivedReceivableCurrentCents?: number
  hide?: boolean
  isWhite?: boolean
  [x: string]: any
}

export function ExtractSummary({
  receivedCurrentCents,
  receivedReceivableCurrentCents,
  hide,
  isWhite,
  ...rest
}: ExtractSummaryProps) {
  const colorsPallete = {
    bg: isWhite ? 'white' : colors['gray.500'],
    textColor: isWhite ? 'grey.600' : 'white',
    strongTextColor: isWhite ? 'grey.600' : 'white',
    linkColor: isWhite ? 'blue' : 'green',
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const [showTooltip2, setShowTooltip2] = useState(false)

  useEffect(() => {
    console.log(receivedCurrentCents, receivedReceivableCurrentCents)
  }, [])

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)

  const renderContent = () => {
    const isHospital = true // Replace with your actual condition
    const userPhone = '(31) 9 9999-5555' // Replace with actual user phone from context

    if (isHospital && true) {
      // Replace with actual condition
      return (
        <>
          <Typography color={colorsPallete.textColor} variant="body2">
            total previsto
          </Typography>
          {!hide && (
            <Typography color={colorsPallete.textColor} variant="h3">
              {formatCurrency(receivedReceivableCurrentCents / 100)}
            </Typography>
          )}
        </>
      )
    }
    if (isHospital && userPhone !== '(31) 9 9999-5555') {
      return (
        <>
          <Typography color={colorsPallete.textColor} variant="body2">
            total previsto
          </Typography>
          <Tooltip
            title="Sem dados suficientes para cálculo dos valores a repassar."
            open={showTooltip2}
            onClose={() => setShowTooltip2(false)}
            onOpen={() => setShowTooltip2(true)}
          >
            <IconButton color="inherit">
              <QuestionMarkIcon />
            </IconButton>
          </Tooltip>
        </>
      )
    }
    return (
      <>
        <Typography color={colorsPallete.textColor} variant="body2">
          total previsto
        </Typography>
        {!hide && (
          <Typography color={colorsPallete.textColor} variant="h3">
            {formatCurrency(receivedReceivableCurrentCents / 100)}
          </Typography>
        )}
      </>
    )
  }

  return (
    <Card
      sx={{
        backgroundColor: colorsPallete.bg,
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'space-between'}>
          <ArrowCircleDownIcon style={{ color: colors['gray.100'] }} />
          {/*   <Typography variant="h6" ml={2} color={colorsPallete.textColor}>
            {dayjs().format('MMM')}
          </Typography> */}
          <Tooltip
            title="Valor estimado baseado no histórico da relação entre repasse realizado e valor total do item na conta."
            open={showTooltip}
            onClose={() => setShowTooltip(false)}
            onOpen={() => setShowTooltip(true)}
          >
            <IconButton sx={{ color: colors['gray.100'] }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-end">
          {renderContent()}
          <Box
            width="100%"
            borderTop={1}
            borderColor={colors['gray.400']}
            justifyContent={'space-between'}
            pt={3}
            display="flex"
            flexDirection="row"
          >
            <Box>
              <Typography fontWeight={500} color={colorsPallete.textColor}>
                {dayjs().format('MMM')}
              </Typography>
              <Typography fontWeight={300} color={colorsPallete.textColor}>
                Mês atual
              </Typography>
            </Box>

            <Box>
              <Typography color={colorsPallete.textColor} variant="body2" textAlign={'right'}>
                Repassado
              </Typography>
              {!hide && (
                <Typography color={colorsPallete.textColor} variant="h4">
                  {formatCurrency(receivedCurrentCents / 100)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
