import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'

import { ToastContainer } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import { useUserStore } from 'stores/users'
import { GetAllExistentUsers } from '~services/Home'
import MiniDrawer from '~components/drawer'
import { colors } from '~constants/colorsPallete'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { MaisRentavelCards } from '~components/mobileCards/MaisRentavel'
import { PrecoProcedimento } from '~components/mobileCards/PrecoProcedimento'
import { MaisGlosam } from '~components/mobileCards/MaisGlosam'
import { RepassesPlanos } from '~components/mobileCards/RepassesPlanos'
import { PacientesMaisAtendidos } from '~components/mobileCards/PacientesMaisAtendidos'
import { MaisAtendidos } from '~components/mobileCards/MaisAtendidos'
import { MaisRealizados, formatCurrency } from '~components/mobileCards/MaisRealizados'
import { ExtractSummary } from '~components/mobileCards/Summary'
import { ExtractFullCard } from '~components/mobileCards/Extract'
import dayjs from 'dayjs'
import { CustomTable, TableMain, Cell } from '~components/table/pushNotificationTable/styled'
import { icon, checkedIcon } from '~utils/styles'
import {
  GetUserComparatives,
  GetUserDisallowances,
  GetUserHome,
  GetUserLogs,
  GetUserRetrospects,
  GetUserTransactions,
  GetUserTransactionsHeader,
} from '~services/MobileUser'
import NoData from '~containers/noData'
import { MoonLoader } from 'react-spinners'
import { override } from 'pages/PushNotificationPage'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DisallowanceObject, TransactionsObject } from '~utils/transform'
import xlsx from 'json-as-xlsx'
import { LogsCard } from '~components/mobileCards/logs'
import LogChart from '~components/homeCards/BarChartCard/LogChart'

export type Score = {
  id: string
  type: string
  attributes: {
    rating: number
    created_at: string
    user_hashid: string
    user_name: string
    data_source: string
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function MobileUserVisualizationPage() {
  const { openDrawer } = useUserStore()

  const [value, setValue] = React.useState(0)
  const [doctor, setDoctor] = useState<any>()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [homeData, setHomeData] = useState<any>(null)
  const [isNet, setIsNet] = useState(true)
  const [userLogs, setUserLogs] = useState<any>([{}])
  const [comparativesData, setComparativesData] = useState<any>([{}])
  const [retrospectsData, setRetrospectsData] = useState<any>([{}])
  const [transactionHeader, setTransactionHeader] = useState<any>([{}])
  const [loading, setLoading] = useState(false)
  const [repassesData, setRepassesData] = useState<any>([{}])
  const [repassadoData, setRepassadoData] = useState<any>([{}])
  const [disallowanceData, setDisallowanceData] = useState<any>([{}])
  const [filterMedicOptions, setFilterMedicOptions] = useState<any[]>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [pageARepassar, setPageARepassar] = React.useState(0)
  const [rowsPerPageARepassar, setRowsPerPageARepassar] = React.useState(10)
  const [rowsPerPageDisallowance, setRowsPerPageDisallowance] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [pageDisallowance, setPageDisallowance] = React.useState(0)
  const [loadingRepassado, setLoadingRepassado] = useState(false)
  const [loadingRepassar, setLoadingRepassar] = useState(false)
  const [date, setDate] = useState<string>(dayjs().format('YYYY/MM/DD'))
  const [exportLoading, setExportLoading] = React.useState(false)
  const settingsDisallowances = {
    fileName: 'Glosas',
  }
  const settingsTransactions = {
    fileName: 'Transações',
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangePageARepassar = (event: unknown, newPage: number) => {
    setPageARepassar(newPage)
  }

  const handleChangePageDisallowance = (event: unknown, newPage: number) => {
    setPageDisallowance(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangeRowsPerPageARepassar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageARepassar(+event.target.value)
    setPageARepassar(0)
  }

  const handleChangeRowsPerPageDisallowance = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageDisallowance(+event.target.value)
    setPageDisallowance(0)
  }

  useEffect(() => {
    GetAllExistentUsers(Uid, access, client)
      .then((response) => {
        setFilterMedicOptions(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/doctor_names?per_page=500', error, null, Uid, 'Get')
      })
  }, [Uid, access, client])

  const GetPendingTransactions = () => {
    setLoadingRepassar(true)
    const params: Record<string, string> = {}
    params['exactly_search_keys[]'] = 'status'
    params['exactly_search_values[]'] = 'pending'

    GetUserTransactions(
      Uid,
      access,
      client,
      doctor,
      params,
      date,
      dayjs(date).endOf('month').format('YYYY/MM/DD'),
      rowsPerPageARepassar,
      pageARepassar + 1,
      'due_date',
    )
      .then((response) => {
        setRepassesData(response.data)
        setLoadingRepassar(false)
      })
      .catch((error) => {
        console.log(error)
        setLoadingRepassar(false)
      })
  }

  const GetPayedTransactions = () => {
    setLoadingRepassado(true)
    const params: Record<string, string> = {}
    params['exactly_search_keys[]'] = 'status'
    params['exactly_search_values[]'] = 'paid'

    GetUserTransactions(
      Uid,
      access,
      client,
      doctor,
      params,
      date,
      dayjs(date).endOf('month').format('YYYY/MM/DD'),
      rowsPerPage,
      page + 1,
      'payment_date',
    )
      .then((response) => {
        setLoadingRepassado(false)
        setRepassadoData(response.data)
      })
      .catch((error) => {
        console.log(error)
        setLoadingRepassar(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      GetUserHome(Uid, access, client, doctor),
      GetUserComparatives(Uid, access, client, doctor),
      GetUserRetrospects(Uid, access, client, doctor),
      GetUserTransactionsHeader(Uid, access, client, doctor),
      GetUserLogs(Uid, access, client, doctor),
    ])
      .then(([home, comparatives, retrospects, transaction, logs]) => {
        setHomeData(home.data.data)
        setComparativesData(comparatives.data.data)
        setRetrospectsData(retrospects.data.data)
        setTransactionHeader(transaction.data)
        setUserLogs(logs)
        setLoading(false)
      })
      .catch((error) => {
        /*    toast.error('Erro ao buscar dados') */
        setLoading(false)
      })
    GetPendingTransactions()
    GetPayedTransactions()
    GetUserDisallowances(Uid, access, client, doctor, date, dayjs(date).endOf('month').format('YYYY/MM/DD'))
      .then((response) => {
        setDisallowanceData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [doctor])

  useEffect(() => {
    GetPendingTransactions()
    GetPayedTransactions()
    GetUserDisallowances(Uid, access, client, doctor, date, dayjs(date).endOf('month').format('YYYY/MM/DD'))
      .then((response) => {
        setDisallowanceData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [page, rowsPerPage, date, pageARepassar, rowsPerPageARepassar])

  // utility function
  const convertData = (data, transformFn) => {
    if (data === undefined || data === null) {
      return []
    }

    return Object.entries(data).map(transformFn)
  }

  // transformation functions
  const transformPatients = ([paciente, value]) => ({
    value,
    paciente,
  })
  const transformPlanos = ([plano, value]) => ({
    value,
    plano,
  })
  const transformProcedimentos = ([plano, value]) => ({
    value: value[2],
    plano: value[0],
    total: value[1],
  })
  // conversions
  const convertedDataPatients = convertData(retrospectsData?.most_profitable_patients, transformPatients)
  const convertedDataPlanos = convertData(retrospectsData?.most_profitable_insurances?.data, transformPlanos)
  const convertedDataProcedimentos = convertData(retrospectsData?.most_profitable_procedures, transformProcedimentos)

  const convertedData =
    comparativesData?.slowest_paying_insurances !== undefined
      ? Object.entries(comparativesData?.slowest_paying_insurances).map(([plano, value]) => ({
          value: `${value} Dias`,
          plano,
        }))
      : []

  const convertedDataQuick =
    comparativesData?.quickest_paying_insurances !== undefined
      ? Object.entries(comparativesData?.quickest_paying_insurances).map(([plano, value]) => ({
          value: `${value} Dias`,
          plano,
        }))
      : []

  const convertDataInsurence =
    comparativesData?.price_per_procedure?.average_by_insurance !== undefined
      ? Object.entries(comparativesData?.price_per_procedure?.average_by_insurance).map(([key, value]) => {
          const [hospital, plano] = key.split(' + ')
          return {
            value,
            plano,
            hospital,
          }
        })
      : []

  const convertLestDataInsurence =
    comparativesData?.least_paying_insurances !== undefined
      ? Object.entries(comparativesData?.least_paying_insurances).map(([key, value]) => {
          const [hospital, plano] = key.split(' + ')
          return {
            value,
            plano,
            hospital,
          }
        })
      : []

  const ExportDisallowances = () => {
    setExportLoading(true)
    GetUserDisallowances(Uid, access, client, doctor, date, dayjs(date).endOf('month').format('YYYY/MM/DD'))
      .then((response) => {
        response.data.data.map((item: any) => {
          DisallowanceObject.content.push({
            dt_glosa: dayjs(item.attributes.disallowance_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            motivo_glosa: item.attributes?.reason,
            situacao: item.attributes?.status,
            nome_paciente: item.attributes?.patient,
            nm_medico: item.attributes.doctor_name,
            convenio: item.attributes.insurance.data.attributes.name,
            cd_procedimento: item.attributes.procedure.data.attributes.code,
            nm_procedimento: item.attributes.procedure.data.attributes.name,
            valor_glosa: formatCurrency(item.attributes.amount_disallowed_cents / 100),
            valor_cobrado: formatCurrency(item.attributes.amount_cents / 100),
          })
        })
        xlsx([DisallowanceObject], settingsDisallowances)
        setExportLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setExportLoading(false)
      })
  }

  const ExportTransactions = (isPast: boolean) => {
    setExportLoading(true)
    const params: Record<string, string> = {}
    if (isPast) {
      params['exactly_search_keys[]'] = 'status'
      params['exactly_search_values[]'] = 'paid'
    } else {
      params['exactly_search_keys[]'] = 'status'
      params['exactly_search_values[]'] = 'pending'
    }

    GetUserTransactions(
      Uid,
      access,
      client,
      doctor,
      params,
      date,
      dayjs(date).endOf('month').format('YYYY/MM/DD'),
      5000,
      1,
      isPast ? 'payment_date' : 'due_date',
    )
      .then((response) => {
        response.data.data.map((item: any) => {
          TransactionsObject.content.push({
            dt_repasse: dayjs(item.attributes?.due_date || item.attributes?.payment_date, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
            procedimento: item.attributes?.procedure?.data?.attributes?.name,
            paciente: item.attributes?.patient,
            convenio: item.attributes?.insurance?.data?.attributes?.name,
            dt_realizacao: dayjs(item.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            cd_procedimento: item.attributes?.procedure?.data?.attributes?.code,
            valor: formatCurrency(item.attributes?.amount_cents / 100),
          })
        })
        xlsx([TransactionsObject], settingsTransactions)
        setExportLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setExportLoading(false)
      })
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#FAFAFA' }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header
              text="Visualização do Médico"
              icon={<SmartphoneIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />}
            />
            <Box display={'flex'} flexDirection={'row'} alignContent={'center'} justifyContent={'center'} mb={3}>
              {filterMedicOptions && (
                <FormControl
                  sx={{
                    minWidth: '200px',
                    marginTop: '8px',
                    marginRight: '8px',
                  }}
                >
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={filterMedicOptions}
                    onChange={(event: any, newValue: any) => {
                      setDoctor(newValue.id)
                    }}
                    sx={{ border: 0 }}
                    disableCloseOnSelect
                    getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.attributes.name}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Médico"
                        placeholder="Médico"
                        sx={{
                          backgroundColor: colors.white,
                          borderRadius: 100,
                          border: 0,
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                {value === 0 || value === 1 || value === 2 || value === 6 ? null : (
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Data"
                      views={['year', 'month']}
                      format="MM/YYYY"
                      defaultValue={dayjs()}
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 100,
                        border: 0,
                        '& fieldset': { border: 'none' },
                      }}
                      onMonthChange={(e) => {
                        setDate(dayjs(e).format('YYYY/MM/01'))
                      }}
                    />
                  </DemoContainer>
                )}
              </LocalizationProvider>
            </Box>
            {loading && (
              <Box alignContent={'center'} justifyContent={'center'} margin={'auto'} mt={5} mb={5}>
                <MoonLoader
                  color={'#009d81'}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  cssOverride={override}
                />
              </Box>
            )}
            {!homeData ? (
              <NoData isMobileView />
            ) : (
              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={value} onChange={handleChange} centered>
                  <Tab label="Home" />
                  <Tab label="Dashboard - Comparativos" />
                  <Tab label="Dashboard - Retrospecto" />
                  <Tab label="A Repassar" />
                  <Tab label="Repassado" />
                  <Tab label="Glosas" />
                  <Tab label="Acessos" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <ExtractSummary
                    receivedCurrentCents={homeData?.received_current_cents || 0}
                    receivedReceivableCurrentCents={homeData?.received_receivable_current_cents || 0}
                    hide={false}
                  />
                  <ExtractFullCard
                    value={homeData?.receivable_cents || 0}
                    label="A REPASSAR"
                    date={dayjs().add(1, 'month')}
                    icon="ArrowCircleRightIcon"
                    onPress={() => console.log('teste')}
                  />
                  <ExtractFullCard
                    value={homeData?.received_cents || 0}
                    label="REPASSADO"
                    date={dayjs().subtract(1, 'month')}
                    icon="ArrowCircleRightIcon"
                    onPress={() => console.log('teste')}
                  />
                  <ExtractFullCard
                    value={homeData?.disallowances_cents || 0}
                    label="GLOSAS"
                    date={dayjs()}
                    icon="warning"
                    onPress={() => console.log('teste')}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MaisRentavelCards
                    description={comparativesData.most_profitable_insurance?.title}
                    percentage={comparativesData.most_profitable_insurance?.percentage}
                  />
                  <PrecoProcedimento
                    procedimento={comparativesData.price_per_procedure?.most_performed_procedure}
                    isLess={false}
                    totalValue={comparativesData.price_per_procedure?.average_price}
                    topProcediments={convertDataInsurence}
                  />
                  <PrecoProcedimento
                    procedimento={comparativesData.price_per_procedure?.most_performed_procedure}
                    isLess={true}
                    totalValue={comparativesData.price_per_procedure?.average_price}
                    topProcediments={convertLestDataInsurence}
                  />
                  <MaisGlosam topProcediments={comparativesData?.most_glosed_insurances} />
                  <RepassesPlanos isLess={false} topProcediments={convertedDataQuick} />
                  <RepassesPlanos isLess={true} topProcediments={convertedData} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Typography variant="body1" mb={3} textAlign={'center'} color={colors['green.default']}>
                    Dados dos últimos 12 meses
                  </Typography>
                  <PacientesMaisAtendidos topProcediments={convertedDataPatients} />
                  <MaisAtendidos topProcediments={convertedDataPlanos} />
                  <MaisRealizados topProcediments={convertedDataProcedimentos} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <CustomTable>
                    {loadingRepassar && (
                      <Box alignContent={'center'} justifyContent={'center'} margin={'auto'} mb={4}>
                        <MoonLoader
                          color={'#009d81'}
                          size={25}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          cssOverride={override}
                        />
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row" alignItems="center" ml={1} justifyContent={'center'}>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: isNet ? colors.blue : colors['gray.200'],
                          color: isNet ? colors.white : colors['gray.500'],
                          mr: 1,
                        }}
                        onClick={() => setIsNet(true)}
                      >
                        Líquido
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: !isNet ? colors.blue : colors['gray.200'],
                          color: !isNet ? colors.white : colors['gray.500'],
                        }}
                        onClick={() => setIsNet(false)}
                      >
                        Bruto
                      </Button>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'}>
                      <Typography
                        variant="body1"
                        mb={1}
                        textAlign={'center'}
                        color={colors['gray.500']}
                        mr={2}
                        border={'1px solid'}
                        borderColor={colors['green.default']}
                        borderRadius={1}
                        p={1}
                      >
                        <b>Valor total: </b>
                        {isNet
                          ? formatCurrency(repassesData?.meta?.net_amount_cents_sum / 100)
                          : formatCurrency(repassesData?.meta?.amount_cents_sum / 100)}
                      </Typography>
                    </Box>
                    <Grid container display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        disabled={exportLoading}
                        onClick={() => {
                          ExportTransactions(false)
                        }}
                        sx={{ backgroundColor: colors['gray.400'], color: colors.white, marginTop: 2, mr: 2 }}
                      >
                        {exportLoading ? 'Carregando...' : 'Download XLS'}
                      </Button>
                    </Grid>
                    <TableMain>
                      <TableHead>
                        <TableRow>
                          <Cell align="center">Data do repasse</Cell>
                          <Cell align="center">Procedimento</Cell>
                          <Cell align="center">Paciente</Cell>
                          <Cell align="center">Convenio</Cell>
                          <Cell align="center">Data de realização</Cell>
                          <Cell align="center">Código do procedimento</Cell>
                          <Cell align="center">Valor</Cell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {repassesData?.data?.map((row: any, index: number) => {
                          return (
                            <TableRow key={index} hover>
                              <TableCell component="th" scope="row" align="center">
                                {dayjs(row.attributes?.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </TableCell>

                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.procedure.data.attributes.name}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.patient}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.insurance.data.attributes.name}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {dayjs(row.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.procedure.data.attributes.code}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {isNet
                                  ? formatCurrency(row.attributes?.net_amount_cents / 100)
                                  : formatCurrency(row.attributes?.amount_cents / 100)}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </TableMain>
                    <TablePagination
                      labelRowsPerPage="Itens por página"
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={repassesData?.meta?.count || 0}
                      rowsPerPage={rowsPerPageARepassar}
                      page={pageARepassar}
                      onPageChange={handleChangePageARepassar}
                      onRowsPerPageChange={handleChangeRowsPerPageARepassar}
                    />
                  </CustomTable>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  {loadingRepassado && (
                    <Box alignContent={'center'} justifyContent={'center'} margin={'auto'} mb={4}>
                      <MoonLoader
                        color={'#009d81'}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        cssOverride={override}
                      />
                    </Box>
                  )}
                  <CustomTable>
                    <Box display="flex" flexDirection="row" alignItems="center" ml={1} justifyContent={'center'}>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: isNet ? colors.blue : colors['gray.200'],
                          color: isNet ? colors.white : colors['gray.500'],
                          mr: 1,
                        }}
                        onClick={() => setIsNet(true)}
                      >
                        Líquido
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: !isNet ? colors.blue : colors['gray.200'],
                          color: !isNet ? colors.white : colors['gray.500'],
                        }}
                        onClick={() => setIsNet(false)}
                      >
                        Bruto
                      </Button>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'}>
                      <Typography
                        variant="body1"
                        mb={1}
                        textAlign={'center'}
                        color={colors['gray.500']}
                        mr={2}
                        border={'1px solid'}
                        borderColor={colors['green.default']}
                        borderRadius={1}
                        p={1}
                      >
                        <b>Valor total: </b>
                        {isNet
                          ? formatCurrency(repassadoData?.meta?.net_amount_cents_sum / 100)
                          : formatCurrency(repassadoData?.meta?.amount_cents_sum / 100)}
                      </Typography>
                    </Box>
                    <Grid container display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        disabled={exportLoading}
                        onClick={() => {
                          ExportTransactions(true)
                        }}
                        sx={{ backgroundColor: colors['gray.400'], color: colors.white, marginTop: 2, mr: 2 }}
                      >
                        {exportLoading ? 'Carregando...' : 'Download XLS'}
                      </Button>
                    </Grid>
                    <TableMain>
                      <TableHead>
                        <TableRow>
                          <Cell align="center">Data do repasse</Cell>
                          <Cell align="center">Procedimento</Cell>
                          <Cell align="center">Paciente</Cell>
                          <Cell align="center">Convenio</Cell>
                          <Cell align="center">Data de realização</Cell>
                          <Cell align="center">Código do procedimento</Cell>
                          <Cell align="center">Valor</Cell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {repassadoData?.data?.map((row: any, index: number) => {
                          return (
                            <TableRow key={index} hover>
                              <TableCell component="th" scope="row" align="center">
                                {dayjs(row.attributes?.payment_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.procedure.data.attributes.name}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.patient}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.insurance.data.attributes.name}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {dayjs(row.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {row.attributes?.procedure.data.attributes.code}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {isNet
                                  ? formatCurrency(row.attributes?.net_amount_cents / 100)
                                  : formatCurrency(row.attributes?.amount_cents / 100)}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </TableMain>
                    <TablePagination
                      labelRowsPerPage="Itens por página"
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={repassadoData?.meta?.count || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CustomTable>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <CustomTable>
                    <Box display="flex" flexDirection="row" alignItems="center" ml={1} justifyContent={'space-between'}>
                      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent={'flex-end'}>
                        <Typography
                          variant="body1"
                          mb={1}
                          textAlign={'left'}
                          color={colors['gray.500']}
                          mr={2}
                          border={'1px solid'}
                          borderColor={colors['blue.light']}
                          borderRadius={1}
                          p={1}
                        >
                          <b>Total de Glosas: </b>
                          {formatCurrency(disallowanceData?.meta?.amount_disallowed_cents / 100 || 0)}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent={'flex-end'}>
                        <Typography
                          variant="body1"
                          mb={1}
                          textAlign={'left'}
                          color={colors['gray.500']}
                          mr={2}
                          border={'1px solid'}
                          borderColor={colors['green.default']}
                          borderRadius={1}
                          p={1}
                        >
                          <b>Total Recursado: </b>
                          {formatCurrency(transactionHeader?.appealed_value / 100 || 0)}
                          <br />
                          <b>Total Recuperado: </b>
                          {formatCurrency(transactionHeader?.paid_value / 100 || 0)}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        disabled={exportLoading}
                        onClick={() => {
                          ExportDisallowances()
                        }}
                        sx={{ backgroundColor: colors['gray.400'], color: colors.white, marginTop: 2, mr: 2 }}
                      >
                        {exportLoading ? 'Carregando...' : 'Download XLS'}
                      </Button>
                    </Grid>
                    <TableMain>
                      <TableHead>
                        <TableRow>
                          <Cell align="center">Data glosa</Cell>
                          <Cell align="center">Motivo glosa</Cell>
                          <Cell align="center">Situação</Cell>
                          <Cell align="center">Nome do paciente</Cell>
                          <Cell align="center">Convênio</Cell>
                          <Cell align="center">Valor da glosa</Cell>
                          <Cell align="center">Valor cobrado</Cell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {disallowanceData?.data
                          ?.slice(
                            pageDisallowance * rowsPerPageDisallowance,
                            pageDisallowance * rowsPerPageDisallowance + rowsPerPageDisallowance,
                          )
                          .map((row: any, index: number) => {
                            return (
                              <TableRow key={index} hover>
                                <TableCell component="th" scope="row" align="center">
                                  {dayjs(row.attributes?.disallowance_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {row.attributes?.reason}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {row.attributes?.status}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {row.attributes?.patient}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {row.attributes?.insurance.data.attributes.name}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {formatCurrency(row.attributes?.amount_disallowed_cents / 100)}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {formatCurrency(row.attributes?.amount_cents / 100)}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </TableMain>
                    <TablePagination
                      labelRowsPerPage="Itens por página"
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={disallowanceData?.meta?.count || 0}
                      rowsPerPage={rowsPerPageDisallowance}
                      page={pageDisallowance}
                      onPageChange={handleChangePageDisallowance}
                      onRowsPerPageChange={handleChangeRowsPerPageDisallowance}
                    />
                  </CustomTable>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  {userLogs?.data && Object.keys(userLogs.data).length === 0 ? (
                    <NoData isLogs />
                  ) : (
                    <>
                      <LogsCard data={[userLogs?.data || {}]} />
                      <LogChart data={[userLogs?.data]} title="Acessos mensais" />
                    </>
                  )}
                </TabPanel>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default MobileUserVisualizationPage
