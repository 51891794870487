import { ApiVersion, BaseService } from '~services/api'

export const CreateNfs = (Uid: string, access: string, client: string, data: any) => {
  return BaseService.post(
    `/shared_api/v1/invoices`,
    {
      invoice: data,
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const InvoiceNotify = (
  Uid: string,
  access: string,
  client: string,
  companyData: any,
  userData: any,
  isBusiness: boolean,
) => {
  return BaseService.post(
    `/institution_api/v1/invoice_instructions/`,
    {
      invoice_instructions: isBusiness ? { company_hashid: companyData } : { user_hashids: userData },
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const UpdateNfs = (id: string, Uid: string, access: string, client: string, data: any) => {
  return BaseService.put(
    `/shared_api/v1/invoices/${id}`,
    {
      invoice: data,
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const GetFilteredNfs = (
  Uid: string,
  access: string,
  client: string,
  pageInfo: { page: string; per_page: string },
  params: Record<string, string>,
) => {
  return BaseService.get(`/shared_api/v1/invoices/?per_page=${pageInfo.per_page}&page=${pageInfo.page}`, {
    timeout: 1000000,
    params,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const PostInvoiceObservations = (Uid: string, access: string, client: string, id: string, revision: string) => {
  return BaseService.post(
    `shared_api/v1/invoices/${id}/invoice_revisions`,
    {
      invoice_revision: {
        description: revision,
      },
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const ListInvoiceObservations = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`shared_api/v1/invoices/${id}/invoice_revisions`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}
