import { Grid, Paper, Typography } from '@mui/material'
import { colors } from '~constants/colorsPallete'
import { BarChart } from '@mui/x-charts'

export default function ChartCard({
  data,
  dataTitle,
  title,
  month,
  data2,
  data3,
  data3Title,
  data2Title,
}: {
  data: number
  dataTitle: string
  title: string
  month: string
  data2: number
  data3: number
  data3Title: string
  data2Title: string
}) {
  return (
    <Grid item xs={12} md={6}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              {title}
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
              {month ? month : 'Mês Atual'}
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <div style={{ height: '380px', width: '100%' }}>
              <BarChart
                grid={{ vertical: true, horizontal: true }}
                borderRadius={2}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: [dataTitle, data2Title, data3Title],

                    colorMap: {
                      type: 'ordinal',
                      colors: [colors['green.default'], colors.blue, colors['gray.500']],
                    },
                  },
                ]}
                series={[{ data: [data, data2, data3] }]}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                  },
                }}
                margin={{ left: 80, top: 5, bottom: 30 }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
