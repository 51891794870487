import styled from 'styled-components'
import { Card, CardMedia, Box, Typography, Button } from '@mui/material'
import bg from '~assets/images/back.png'

export const CustomCard = styled(Card)`
  && {
    margin-top: 20px;
    display: flex;
    box-shadow: 0px 34px 34px rgba(13, 46, 97, 0.05);
    border-radius: 15px;
    background-size: contain;
    width: 95%;
    height: 129px;
    background-color: #f8fdff;
    background-image: url(${bg});
  }
`

export const CustomBox = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`
export const Title = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 1.2rem;
    color: #414aa1;
    line-height: 1.6rem;
  }
`

export const SubTitle = styled(Typography)`
  && {
    font-size: 12px;
    margin-top: 10px;
    color: #697382;
    width: 160px;
  }
`

export const CustomButton = styled(Button)`
  && {
    color: #009d81;
    margin-top: 40px;

    font-size: 0.8rem;
  }
`
export const Media = styled(CardMedia)`
  && {
    position: absolute;
    height: 60px;
    width: 60px;
    margin-left: 11%;
    margin-top: 70px;
  }
`
