import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid } from '@mui/material'
import { useState } from 'react'
import { useUserStore } from 'stores/users'
import { ToastContainer, toast } from 'react-toastify'
import { SendSlackMessage } from '~services/Slack'
import { SendConversationMessage, UpdateConversation } from '~services/Conversations'
import { CustomInput } from '../modalEditPush/styled'
import { style } from './styled'

export default function ModalResponseComunicacao({
  handleClose,
  open,
  data,
  updateData,
}: {
  handleClose: any
  open: boolean
  data: any
  updateData?: () => void
}) {
  const [message, setMessage] = useState('')
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [loading, setLoading] = useState(false)

  const updateStatus = (status) => {
    UpdateConversation(data.id, Uid, access, client, status)
      .then(() => {
        updateData()
        handleClose()
        toast.success('Mensagem enviada com sucesso!')
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        SendSlackMessage(
          `/shared_api/v1/conversations/${data.id}/`,
          error,
          { conversation: { status: status } },
          Uid,
          'Put',
        )
      })
  }

  const handleSend = () => {
    setLoading(true)
    SendConversationMessage(data.id, Uid, access, client, message)
      .then(() => {
        updateStatus('closed')
      })
      .catch((error) => {
        SendSlackMessage(
          `/shared_api/v1/conversations/${data?.id}/messages`,
          error,
          {
            message: { content: message },
          },
          Uid,
          'Post',
        )
        toast.error('Ooops, algo deu errado! Tente novamente.')
      })
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" component="h2">
            Responder
            <br />
            inconformidade
          </Typography>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6" component="h2" gutterBottom mt={2}>
                {data?.attributes?.procedure}
              </Typography>
              {data?.attributes?.patient && (
                <Typography variant="body1" component="h4">
                  Paciente: {data?.attributes?.patient}
                </Typography>
              )}
            </Grid>

            <Grid item xs={8}>
              <CustomInput
                fullWidth
                placeholder="Resposta"
                multiline
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: '20px',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Button disabled={loading} onClick={handleClose} sx={{ mr: 1, color: '#984A4A' }}>
              Cancelar
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                handleSend()
              }}
              sx={{ mr: 3, color: '#009D81' }}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
