import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { icon, checkedIcon } from '~utils/styles'
import { GetAllUsers } from '~services/Home'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { GetAllProcedureCodes, GetInsurances } from '~services/Productions'
import { SendSlackMessage } from '~services/Slack'

interface ProductionFiltersProps {
  onFilterChange: (filters: any) => void
}

const ProductionFilters: React.FC<ProductionFiltersProps> = ({ onFilterChange }) => {
  const [filterMedicOptions, setFilterMedicOptions] = useState<any[]>([])
  const [insurancesOptions, setInsurancesOptions] = React.useState<any>([])
  const [procedureCodesOptions, setProcedureCodesOptions] = React.useState<any>([])
  const [conversations, setConversations] = React.useState('')
  const [date, setDate] = useState<string>('')
  const [repasseDate, setRepasseDate] = useState<string>('')
  const [endRepasseDate, setEndRepasseDate] = useState<string>('')
  const [consultationCode, setConsultationCode] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [insurances, setInsurances] = useState<any>()
  const [procedureCodes, setProcedureCodes] = useState<any>()
  const [doctor, setDoctor] = useState<any>()
  const [status, setStatus] = React.useState('')
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const selectedDoctorOption = filterMedicOptions.filter((option) => doctor?.includes(option))
  const selectedInsuranceOption = insurancesOptions.filter((option) => insurances?.includes(option.id))

  const clearFilters = () => {
    // Reset filter states
    setDoctor('')
    setInsurances('')
    setProcedureCodes('')
    setStatus('')
    setConversations('')
    setConsultationCode('')
    setDate('')
    setEndDate('')
    setRepasseDate('')
    setEndDate('')
    onFilterChange({
      doctor: '',
      date: '',
      repasseDate: '',
      endRepasseDate: '',
      endDate: '',
      status: '',
      insurances: '',
      procedureCodes: '',
      conversations: '',
    })
  }

  useEffect(() => {
    GetAllUsers(Uid, access, client)
      .then((response) => {
        setFilterMedicOptions(response.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/doctor_names?per_page=500', error, null, Uid, 'Get')
      })
    GetAllProcedureCodes(Uid, access, client)
      .then((response) => {
        setProcedureCodesOptions(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/procedures', error, null, Uid, 'Get')
      })
    GetInsurances(Uid, access, client)
      .then((response) => {
        setInsurancesOptions(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/insurances/?per_page=1000', error, null, Uid, 'Get')
      })
  }, [Uid, access, client])

  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }

  /*   const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  }) */

  return (
    <>
      {!isOpen ? (
        <Grid container direction="row" justifyContent="flex-end" mb={2}>
          <Button
            disableElevation
            variant="contained"
            endIcon={<FilterAltRoundedIcon />}
            sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Abrir Filtros
          </Button>
        </Grid>
      ) : (
        <>
          <Grid container direction="row" justifyContent="flex-end" mb={2}>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={0} pb={0}>
              <Button
                disableElevation
                variant="contained"
                endIcon={<FilterAltRoundedIcon />}
                sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              >
                Fechar Filtros
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
            p={3}
            mb={2}
            border={'1px solid #E0E0E0'}
            borderRadius={'3px'}
            sx={{ backgroundColor: colors['white.200'] }}
          >
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  id="demo-customized-select-native"
                  value={status}
                  sx={{
                    backgroundColor: colors.white,
                    borderRadius: 100,
                    border: 0,
                    '& fieldset': { border: 'none' },
                  }}
                  onChange={handleChangeStatus}
                >
                  <MenuItem sx={{ pl: '10px' }} value={'Sem Regra'}>
                    Sem Regra
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'Outra Via'}>
                    Outra Via
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'Repassado'}>
                    Repassado
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'A Repassar'}>
                    A Repassar
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'Glosado'}>
                    Glosado
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'Não Recebido'}>
                    Não Recebido
                  </MenuItem>
                  <MenuItem sx={{ pl: '10px' }} value={'Não Faturado'}>
                    Não Faturado
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <FormControl fullWidth>
                  <DatePicker
                    label="Início do Período de Realização"
                    format="DD/MM/YYYY"
                    defaultValue={dayjs().startOf('month')}
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: 100,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                    onChange={(e) => {
                      setDate(dayjs(e).format('DD/MM/YYYY'))
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <FormControl fullWidth>
                  <DatePicker
                    label="Fim do Período de Realização"
                    format="DD/MM/YYYY"
                    minDate={dayjs(date)}
                    defaultValue={dayjs()}
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: 100,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                    onChange={(e) => {
                      setEndDate(dayjs(e).format('DD/MM/YYYY'))
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              {filterMedicOptions && (
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    limitTags={4}
                    id="checkboxes-tags-demo"
                    options={['Selecionar Todos', ...filterMedicOptions]} // Adicione a opção de selecionar todos
                    onChange={(event, newValue) => {
                      // Verifique se a opção de Selecionar Todos foi selecionada
                      if (newValue.some((item) => item === 'Selecionar Todos')) {
                        // Se já estava selecionada e foi clicada novamente, remova todos
                        if (selectedDoctorOption.length === filterMedicOptions.length) {
                          setDoctor([])
                        } else {
                          // Senão, selecione todos
                          setDoctor(filterMedicOptions.map((item) => item))
                        }
                      } else {
                        // Manipulação para seleção normal
                        setDoctor(newValue.map((item) => item))
                      }
                    }}
                    value={selectedDoctorOption}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            selected ||
                            (option === 'Selecionar Todos' && selectedDoctorOption.length === filterMedicOptions.length)
                          }
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Médicos"
                        placeholder="Selecione os médicos"
                        sx={{
                          backgroundColor: colors.white,
                          borderRadius: 100,
                          border: 0,
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              {insurancesOptions && (
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={insurancesOptions}
                    value={selectedInsuranceOption}
                    onChange={(event: any, newValue: any) => {
                      setInsurances(newValue.map((item: { id: string }) => item.id))
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option: { attributes: { name: string } }) => option.attributes?.name}
                    ListboxProps={{ style: { maxHeight: 500 } }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.attributes.name}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Convênio"
                        placeholder="Convênios"
                        sx={{
                          backgroundColor: colors.white,
                          borderRadius: 100,
                          border: 0,
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <FormControl fullWidth>
                  <DatePicker
                    label="Início do Período de Competência do Repasse"
                    format="DD/MM/YYYY"
                    defaultValue={dayjs().startOf('month')}
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: 100,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                    onChange={(e) => {
                      setRepasseDate(dayjs(e).format('DD/MM/YYYY'))
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            {/*   <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <FormControl fullWidth>
                  <DatePicker
                    label="Fim do Período de Competência do Repasse"
                    format="DD/MM/YYYY"
                    minDate={dayjs(date)}
                    defaultValue={dayjs()}
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: 100,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                    onChange={(e) => {
                      setEndRepasseDate(dayjs(e).format('DD/MM/YYYY'))
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Número do Procedimento"
                  placeholder="Número do Procedimento"
                  value={procedureCodes}
                  onChange={(e) => setProcedureCodes(e.target.value)}
                  sx={{
                    backgroundColor: colors.white,
                    borderRadius: 100,
                    border: 0,
                    '& fieldset': { border: 'none' },
                  }}
                />
              </FormControl>
            </Grid> */}
            <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Número de atendimento"
                  placeholder="Número de atendimento"
                  value={consultationCode}
                  onChange={(e) => setConsultationCode(e.target.value)}
                  sx={{
                    backgroundColor: colors.white,
                    borderRadius: 100,
                    border: 0,
                    '& fieldset': { border: 'none' },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
              <Box display="flex" flexDirection="row" alignContent={'center'} justifyContent={'flex-end'} mt={3}>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{ backgroundColor: colors.success, borderRadius: 100, mr: 2 }}
                  onClick={() => {
                    onFilterChange({
                      doctor,
                      date,
                      endDate,
                      repasseDate,
                      endRepasseDate,
                      status,
                      insurances,
                      procedureCodes,
                      conversations,
                      consultationCode,
                    })
                  }}
                >
                  Filtrar
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  sx={{ borderColor: colors.error, color: colors.error, borderRadius: 100 }}
                  onClick={clearFilters}
                >
                  Limpar filtros
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default ProductionFilters
