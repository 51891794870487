import { ApiVersion, BaseService } from '~services/api'

export const GetMedicalProductions = (
  Uid: string,
  access: string,
  client: string,
  pageInfo: { per_page: string; page: string },
  params: Record<string, string>,
) => {
  return BaseService.get(
    `/institution_api/v1/medical_productions?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
    {
      params,
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}
