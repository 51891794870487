import ReactDOM from 'react-dom'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { CssBaseline } from '@material-ui/core'

ReactDOM.render(
  <BrowserRouter>
    <CssBaseline />
    <ToastContainer />
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
)
