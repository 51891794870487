import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProtectedRouteProps } from './routes/PrivateRoute'
import ProtectedRoute from './routes/PrivateRoute'
import CustomRoute from '~routes/CustomRoute'
import Dashboard from 'pages/HomePage'
import Comunicacao from 'pages/ConversationsPage'
import PushNotifications from 'pages/PushNotificationPage'
import Nfs from 'pages/ReceiptsPage'
import Producao from 'pages/MedicalProductionPage'
import AllCompanies from 'pages/CompaniesPage'
import UserManagement from 'pages/UserManagementPage'
import RatingPage from 'pages/RatingPage'
import GlobalStyle from '~assets/styles/global'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { Login } from 'pages/AuthPage/Login'
import { useUserStore } from 'stores/users'
import { CheckSendStatus } from '~services/SheetUpload'
import { toast } from 'react-toastify'
import HeaderSheetSend from '~components/HeaderSheetSend'
import { GetUsers } from '~services/User'
import MobileUserVisualizationPage from 'pages/MobileUserVisualization'

const App: React.FC = () => {
  const isLogged = useUserStore((state) => state.isLogged)
  const Uid = useUserStore((state) => state.phone)
  const setTokenSheet = useUserStore((state) => state.setTokenSheet)
  const tokenSheet = useUserStore((state) => state.tokenSheet)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    routeName: '',
    isAuthenticated: isLogged,
    authenticationPath: '/login',
  }

  const checkStatusAndRetry = (id, Uid, access, client) => {
    CheckSendStatus(id, Uid, access, client)
      .then((response) => {
        if (response.data.data.attributes.status === 'succeeded') {
          toast.success('Planilha cadastrada com sucesso!')
          setTokenSheet(null)
        } else if (
          response.data.data.attributes.status === 'in_progress' ||
          response.data.data.attributes.status === 'created'
        ) {
          setTimeout(() => {
            checkStatusAndRetry(id, Uid, access, client)
          }, 3000)
        } else {
          setTokenSheet(null)
          toast.error('Erro! Planilha fora do formato padrão.')
        }
      })
      .catch((error) => {
        console.error(error)
        setTokenSheet(null)
        toast.error('Erro ao cadastrar planilha!')
      })
  }

  useEffect(() => {
    if (tokenSheet) {
      checkStatusAndRetry(tokenSheet, Uid, access, client)
    }
  }, [tokenSheet])

  return (
    <>
      <GlobalStyle />
      {tokenSheet && <HeaderSheetSend />}
      <Routes>
        <Route path="/" element={<CustomRoute isLoggedIn={isLogged} />} />
        <Route
          path="dashboard_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              routeName="dashboard_page"
              {...defaultProtectedRouteProps}
              outlet={<Dashboard />}
            />
          }
        />
        <Route
          path="conversations_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              {...defaultProtectedRouteProps}
              routeName="conversations_page"
              outlet={<Comunicacao />}
            />
          }
        />
        <Route
          path="mobileUserVisualization_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              {...defaultProtectedRouteProps}
              routeName="mobileUserVisualization_page"
              outlet={<MobileUserVisualizationPage />}
            />
          }
        />
        <Route
          path="push_notifications_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              {...defaultProtectedRouteProps}
              routeName="push_notifications_page"
              outlet={<PushNotifications />}
            />
          }
        />
        <Route
          path="companies_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              routeName="companies_page"
              {...defaultProtectedRouteProps}
              outlet={<AllCompanies />}
            />
          }
        />
        <Route
          path="invoices_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              routeName="invoices_page"
              {...defaultProtectedRouteProps}
              outlet={<Nfs />}
            />
          }
        />
        <Route
          path="medical_productions_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              routeName="medical_productions_page"
              {...defaultProtectedRouteProps}
              outlet={<Producao />}
            />
          }
        />
        <Route
          path="userManagement"
          element={
            <ProtectedRoute
              authenticationPath={''}
              {...defaultProtectedRouteProps}
              routeName="management"
              outlet={<UserManagement />}
            />
          }
        />
        <Route
          path="scores_page"
          element={
            <ProtectedRoute
              authenticationPath={''}
              routeName="scores_page"
              {...defaultProtectedRouteProps}
              outlet={<RatingPage />}
            />
          }
        />
        <Route path="login" element={<Login />} />
      </Routes>
    </>
  )
}

export default App
