import { Navigate } from 'react-router-dom'

const CustomRoute = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return <Navigate to="/dashboard_page" />
  } else {
    return <Navigate to="/login" />
  }
}

export default CustomRoute
