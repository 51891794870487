import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, Grid, InputBase, TextField, styled } from '@mui/material'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormik } from 'formik'
import { useUserStore } from 'stores/users'
import React from 'react'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo/DemoContainer'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'

import { colors } from '~constants/colorsPallete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import moment from 'moment'
import { ptBR } from '@mui/x-date-pickers/locales'
import { SendSlackMessage } from '~services/Slack'
import { CreatePushNotifications } from '~services/PushNotifications'
import { LoginSchema } from 'schemas'
import { style } from './styled'
import { CustomInput } from '../modalCreateUser/styled'
import { checkedIcon, icon } from '~utils/styles'
moment.locale('pt-br')

export default function ModalCreatePush({
  handleClose,
  open,
  doctors,
}: {
  handleClose: any
  open: boolean
  doctors: any
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const Uid = useUserStore((state) => state.phone)
  const id = useUserStore((state) => state.id)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [message, setMessage] = React.useState('')
  const [sendNow, setSendNow] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [selectedDoctor, setSelectedDoctor] = React.useState<any>()
  const [selectedData, setSelectedData] = React.useState('')
  const [selectedProcedimento, setSelectedProcedimento] = React.useState<any>([])

  const submitPush = () => {
    const data = {
      title: title,
      body: message,
      schedule_time: selectedData,
      user_hashids: selectedProcedimento,
    }
    CreatePushNotifications(Uid, access, client, data)
      .then(() => {
        toast.success('Push criado com sucesso!')
        setTimeout(() => window.location.reload(), 3000)
      })
      .catch((error) => {
        console.log(error)
        SendSlackMessage(
          '/institution_api/v1/push_notifications',
          error,
          {
            push_notification: {
              title: title,
              body: message,
              schedule_time: selectedData,
              user_hashids: selectedProcedimento,
            },
          },
          Uid,
          'Post',
        )
        toast.error('Ooops, algo deu errado! Tente novamente.')
      })
  }

  const formik = useFormik({
    initialValues: {
      email: 'teste@gmail.com',
      password: '123456',
      submit: null,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box sx={style} width={isMobile ? '80%' : '50%'}>
            <Typography variant="h5" component="h2">
              Nova Push Notification
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
                  Crie uma nova campanha de envio de push notifications.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid item ml={4} mt={1}>
                    {doctors && (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={[{ attributes: { name: 'Selecionar Todos' }, id: 'selectAll' }, ...doctors]}
                        onChange={(event, newValue) => {
                          console.log('hahaha')
                          // Verifica se a opção de Selecionar Todos foi selecionada/desselecionada
                          const isSelectAllSelected = newValue.some((item) => item.id === 'selectAll')
                          const allOptionsAreSelected = newValue.length - 1 === doctors.length

                          if (isSelectAllSelected && !allOptionsAreSelected) {
                            // Seleciona todos os médicos se a opção Selecionar Todos for selecionada e nem todos os médicos estavam selecionados antes
                            setSelectedProcedimento(doctors.map((item) => item.id))
                          } else if (isSelectAllSelected && allOptionsAreSelected) {
                            // Desseleciona todos os médicos se a opção Selecionar Todos for selecionada e todos os médicos já estavam selecionados
                            setSelectedProcedimento([])
                          } else {
                            // Trata a seleção normal (não envolvendo Selecionar Todos)
                            setSelectedProcedimento(newValue.map((item) => item.id))
                          }
                        }}
                        value={doctors.filter((item) => selectedProcedimento.includes(item.id))}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.attributes.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                selected ||
                                (option.id === 'selectAll' && selectedProcedimento.length === doctors.length)
                              }
                            />
                            {option.attributes.name}
                          </li>
                        )}
                        style={{ width: 270 }}
                        renderInput={(params) => <TextField {...params} label="Médicos" placeholder="Médicos" />}
                      />
                    )}
                  </Grid>
                  <Grid item ml={4} mt={2}>
                    <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
                      Programe seu envio ou envie imediatamente.
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                      <DemoContainer components={['DateTimePicker']}>
                        <MobileDateTimePicker
                          disabled={sendNow}
                          orientation="landscape"
                          views={['year', 'month', 'day', 'hours', 'minutes']}
                          disablePast
                          localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                          onChange={(e: any) => {
                            setSelectedData(dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ[Z]'))
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <Button
                      variant="contained"
                      startIcon={sendNow ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      onClick={() => {
                        const currentTime = moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ[Z]').toString()
                        setSelectedData(currentTime)
                        setSendNow(!sendNow)
                      }}
                      sx={{
                        mt: 3,
                        backgroundColor: sendNow ? '#009D81' : colors['gray.200'],
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: sendNow ? '#009D81' : colors['gray.200'],
                        },
                      }}
                    >
                      Enviar agora
                    </Button>
                  </Grid>
                </Grid>

                <CustomInput
                  fullWidth
                  placeholder="Título"
                  sx={{ mt: 2 }}
                  value={title}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                />
                <CustomInput
                  fullWidth
                  placeholder="Mensagem"
                  multiline
                  sx={{ mt: 2 }}
                  inputProps={{ maxLength: 120 }}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '20px',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Button
                onClick={() => {
                  handleClose()
                  setSelectedDoctor('')
                  setSelectedData('')
                  setSelectedProcedimento('')
                  setMessage('')
                  setTitle('')
                }}
                sx={{ mr: 1, color: '#984A4A' }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  submitPush()
                }}
                sx={{ mr: 3, color: '#009D81' }}
              >
                Registrar
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </>
  )
}
