import { ApiVersion, BaseService } from '~services/api'

export const GetUserPush = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/push_notifications/?per_page=100`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserScores = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/shared_api/v1/scores?per_page=1000?order_by=asc`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetSpecificScores = (Uid: string, access: string, client: string, id) => {
  return BaseService.get(`/shared_api/v1/scores/${id}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllUsers = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/doctor_names?per_page=5000`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllExistentUsers = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/users?per_page=500`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllCompanys = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/institution_companies?per_page=500`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetHomeInfo = (Uid: string, access: string, client: string, params: Record<string, string> = {}) => {
  return BaseService.get(`/institution_api/v1/statistics/institution_dashboard`, {
    params,
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetHeaders = (Uid: string, access: string, client: string, params: Record<string, string> = {}) => {
  return BaseService.get(`/institution_api/v1/statistics/institution_headers`, {
    params,
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetHomeInvoices = (Uid: string, access: string, client: string) => {
  return BaseService.get(
    `/shared_api/v1/invoices/?per_page=100&exactly_search_keys[]=received_status&exactly_search_values[]=solicited&exactly_search_keys[]=verification_status&exactly_search_values[]=pending`,
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}
