import React, { useEffect } from 'react'
import logo from '~assets/images/Seiwa.png'
import user from '~assets/images/icon.png'
import bellnot from '~assets/images/bell-not.png'
import { Box, Divider, Grid, Typography, styled } from '@mui/material'
import { useUserStore } from 'stores/users'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PeopleIcon from '@mui/icons-material/People'
import { GetUserInfo, GetUserNotifications, UpdateUserNotification } from '~services/User'
import CheckIcon from '@mui/icons-material/Check'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import { colors } from '~constants/colorsPallete'
import ModalHistory from '~components/modais/modalHistory'
import { GetConversationData } from '~services/Conversations'
import { LogoBox } from './styled'
import { SendSlackMessage } from '~services/Slack'

export default function Header({ icon, text }: { icon: any; text: string }) {
  const navigate = useNavigate()
  const location = useLocation()
  const permission = useUserStore((state) => state.User.high_level_permissions)
  const loggedIn = useUserStore((state) => state.setLogged)
  const clearUser = useUserStore((state) => state.clearUser)
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [notificationData, setNotificationData] = React.useState(null)
  const id = useUserStore((state) => state.id)
  const [hasNotification, setHasNotification] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorNotification, setAnchorNotification] = React.useState(null)
  const from = location.state?.from?.pathname || '/'
  const create = location.state?.from?.pathname || '/sign-in'
  const management = location.state?.from?.pathname || '/userManagement'
  const [open, setOpen] = React.useState(false)
  const [dataHistory, setDataHistory] = React.useState()
  const [amountNotifications, setAmountNotifications] = React.useState(0)

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      marginTop: '50px',
    },
  }))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    // Adicione aqui a lógica para logout
    loggedIn(false)
    clearUser()
    navigate(from, { replace: true })
    setAnchorEl(null)
  }

  const handleCreateUser = () => {
    navigate(create, { replace: true })
    setAnchorEl(null)
  }

  const handleManager = () => {
    navigate(management, { replace: true })
    setAnchorEl(null)
  }

  const updateStatusNotification = (data) => {
    UpdateUserNotification(data, Uid, access, client)
      .then(() => {
        getInfoNotifications()
      })
      .catch((error) => {
        SendSlackMessage(`/shared_api/v1/notifications/${data}`, error, null, Uid, 'Put')
        console.log(error)
      })
  }

  const getInfoNotifications = () => {
    GetUserInfo(id, Uid, access, client)
      .then((response) => {
        GetUserNotifications(Uid, access, client)
          .then((response) => {
            setNotificationData(response.data.data)
          })
          .catch((error) => {
            SendSlackMessage(`/shared_api/v1/notifications?sort_by=created_at&order_by=desc`, error, null, Uid, 'Get')
            console.log(error)
          })
        if (response.data.data.attributes.notifications > 0) {
          setAmountNotifications(response.data.data.attributes.notifications)
          setHasNotification(true)
        } else {
          setHasNotification(false)
        }
      })
      .catch((error) => {
        SendSlackMessage(`/institution_api/v1/institution_users/${id}`, error, null, Uid, 'Get')
        console.log(error)
      })
  }

  useEffect(() => {
    getInfoNotifications()
  }, [])

  return (
    <>
      <ModalHistory handleClose={() => setOpen(false)} open={open} data={dataHistory} />
      <Grid container>
        <Grid item xs={6}>
          <img src={logo} width={70} />
        </Grid>
        <Grid item xs={6} alignContent={'flex-end'} display={'flex'}>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item lg={12} md={12} xs={12} sx={{ textAlign: 'right' }}>
              <IconButton onClick={handleClick}>
                <img src={user} width={40} alt="User" />
              </IconButton>

              <IconButton
                onClick={(event) => {
                  setAnchorNotification(event.currentTarget)
                }}
                style={{ backgroundColor: 'white', borderRadius: 8, width: 40, height: 40 }}
              >
                {hasNotification && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 22,
                      left: 20,
                      backgroundColor: 'red',
                      borderRadius: 12,
                      width: 22,
                      height: 22,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px', color: 'white', fontWeight: '500', marginTop: '2px' }}>
                      {amountNotifications}
                    </Typography>
                  </Box>
                )}

                <img src={bellnot} width={24} style={{ cursor: 'pointer' }}></img>
              </IconButton>

              <StyledMenu
                id="header-button"
                MenuListProps={{
                  'aria-labelledby': 'header-button-demo',
                }}
                anchorEl={anchorNotification}
                open={Boolean(anchorNotification)}
                onClose={() => {
                  setAnchorNotification(null)
                }}
              >
                {notificationData?.map((data, index) => (
                  <>
                    <MenuItem
                      onClick={() => {
                        setAnchorNotification(null)
                        GetConversationData(data.attributes.metadata.resource_id, Uid, access, client)
                          .then((response) => {
                            setDataHistory(response.data.data)
                            updateStatusNotification(data.id)

                            setOpen(true)
                          })
                          .catch((error) => {
                            SendSlackMessage(
                              `/shared_api/v1/conversations/${data.attributes.metadata.resource_id}`,
                              error,
                              null,
                              Uid,
                              'Get',
                            )
                            console.log(error)
                          })
                      }}
                    >
                      {data.attributes.read ? (
                        <CheckIcon sx={{ mr: '10px' }} />
                      ) : (
                        <NotificationImportantIcon sx={{ mr: '10px', color: colors.error }} />
                      )}

                      {data.attributes.read ? 'Mensagem visualizada' : data.attributes.content}
                    </MenuItem>
                    {index !== notificationData.length - 1 && <Divider sx={{ my: 0.5 }} />}
                  </>
                ))}
              </StyledMenu>

              <StyledMenu
                id="header-button"
                MenuListProps={{
                  'aria-labelledby': 'header-button-demo',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {permission?.includes('institution_admin') && (
                  <>
                    <MenuItem onClick={handleManager}>
                      <PeopleIcon sx={{ mr: '10px' }} />
                      Gerenciar usuários
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                  </>
                )}
                <MenuItem onClick={handleLogout}>
                  <LogoutOutlinedIcon sx={{ mr: '10px' }} />
                  Logout
                </MenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LogoBox>
        {icon}
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', color: colors['gray.500'] }}>
          {text}
        </Typography>
      </LogoBox>
    </>
  )
}
