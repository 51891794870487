import axios from 'axios'

const api = (baseURL, isMultiForm?: boolean) => {
  return axios.create({
    baseURL,
    timeout: 1000000,
    headers: {
      'Content-Type': isMultiForm ? 'multipart/form-data' : 'application/json',
      Accept: '*/*',
      APP_VERSION: '2.5.1',
    },
  })
}

export default api
