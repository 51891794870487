import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import ModalConfirmDoc from '../modalConfirmDoc'

import { ToastContainer } from 'react-toastify'

import { useUserStore } from 'stores/users'

import { useDropzone } from 'react-dropzone'
import { Container } from '~components/dropzone'
import 'dayjs/locale/pt-br'

import { uploadSheet } from '~services/SheetUpload'
import MoonLoader from 'react-spinners/MoonLoader'
import DeleteIcon from '@mui/icons-material/Delete'
import { override } from 'pages/PushNotificationPage'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export default function ModalUploadSheet({
  handleClose,
  open,
}: {
  handleClose: VoidFunction
  open: boolean
  doctors?: any
}) {
  const [openSucess, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [acceptedFile, setAcceptedFile] = React.useState(null)
  const Uid = useUserStore((state) => state.phone)
  const setTokenSheet = useUserStore((state) => state.setTokenSheet)
  const tokenSheet = useUserStore((state) => state.tokenSheet)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleOpen = () => {
    handleClose()
    setOpen(true)
  }
  const handleDismiss = () => setOpen(false)

  const onSubmit = () => {
    setLoading(true)

    uploadSheet(acceptedFile, Uid, access, client)
      .then((res) => {
        setTokenSheet(res.data.data.id)
      })
      .catch((err) => {
        setTokenSheet(null)
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(false)
  }, [tokenSheet])

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setAcceptedFile(acceptedFiles[0])
    },
  })

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ModalConfirmDoc handleOpen={handleOpen} handleClose={handleDismiss} open={openSucess} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" component="h2">
            Enviar nova Planilha de dados
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {loading || tokenSheet ? (
                <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
                  <MoonLoader
                    color={'#009d81'}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    cssOverride={override}
                  />
                  <Typography variant="h6" component="div" mt={2} sx={{ flexGrow: 1, textAlign: 'center' }}>
                    Enviando sua planilha, isso pode demorar um pouco...
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                    Sinta-se livre para navegar em outras páginas enquanto isso.
                  </Typography>
                </Box>
              ) : (
                <div className="container">
                  <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>Arraste ou clique para adicionar novos arquivos</p>
                  </Container>
                  <aside>
                    {acceptedFile ? (
                      <>
                        <Typography variant="h6" mt={4}>
                          Planilha adicionada
                        </Typography>
                        <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
                          <Typography variant="body1" mt={2} mr={4}>
                            {acceptedFile?.name}
                          </Typography>
                          <Button onClick={() => setAcceptedFile(null)} variant="text">
                            <DeleteIcon />
                          </Button>
                        </Box>
                      </>
                    ) : null}
                  </aside>
                </div>
              )}
            </Grid>
          </Grid>
          <Box marginTop={'20px'} justifyContent={'flex-end'} display={'flex'}>
            <Button
              onClick={() => {
                setAcceptedFile(null)
                handleClose()
              }}
              sx={{ mr: 2, color: '#4B4A64' }}
            >
              Fechar
            </Button>
            <Button
              onClick={() => onSubmit()}
              variant="contained"
              disabled={loading || tokenSheet !== null}
              sx={{ color: 'white', backgroundColor: '#009D81' }}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
