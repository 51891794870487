import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import ModalConfirmDoc from '../modalConfirmDoc'
import { ToastContainer, toast } from 'react-toastify'
import { useUserStore } from 'stores/users'
import 'dayjs/locale/pt-br'
import { EditCompany } from '~services/Companies'
import { NfsSchema } from 'schemas'
import { style } from './styled'

export default function ModalEditBusiness({
  handleClose,
  open,
  data,
  id,
}: {
  handleClose: VoidFunction
  data: any
  open: boolean
  id: string
}) {
  const [openSucess, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleOpen = () => {
    handleClose()
    setOpen(true)
  }
  const handleDismiss = () => setOpen(false)

  const formik = useFormik({
    initialValues: {
      cpf_cnpj: '',
      name: '',
      email: '',
      municipal_registration: '',
      state_registration: '',
      website: '',
      address: '',
    },
    validationSchema: NfsSchema,

    onSubmit: () => {
      setLoading(true)
      EditCompany(Uid, access, client, id, formik.values)
        .then(() => {
          toast.success('Empresa editada com sucesso!')
          setLoading(false)
          setTimeout(() => window.location.reload(), 3000)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          toast.error('Ooops, algo deu errado! Tente novamente.')
        })
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  useEffect(() => {
    formik.setFieldValue('cpf_cnpj', data.attributes?.cpf_cnpj)
    formik.setFieldValue('name', data.attributes?.name)
    formik.setFieldValue('email', data.attributes?.email)
    formik.setFieldValue('municipal_registration', data.attributes?.municipal_registration)
    formik.setFieldValue('state_registration', data.attributes?.state_registration)
    formik.setFieldValue('website', data.attributes?.website)
    formik.setFieldValue('address', data.attributes?.address)
  }, [data])

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ModalConfirmDoc handleOpen={handleOpen} handleClose={handleDismiss} open={openSucess} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography variant="h5" component="h2">
                Editar dados da empresa
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                    <TextField
                      fullWidth
                      autoComplete="Nome da Empresa"
                      type="text"
                      label="Nome da Empresa"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      fullWidth
                      autoComplete="cpf_cnpj"
                      type="number"
                      label="CPF/CNPJ"
                      {...getFieldProps('cpf_cnpj')}
                      error={Boolean(touched.cpf_cnpj && errors.cpf_cnpj)}
                      helperText={touched.cpf_cnpj && errors.cpf_cnpj}
                    />
                    <TextField
                      fullWidth
                      autoComplete="email"
                      type="text"
                      label="E-mail"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <TextField
                      fullWidth
                      autoComplete="municipal_registration"
                      type="text"
                      label="Registro Municipal"
                      {...getFieldProps('municipal_registration')}
                      error={Boolean(touched.municipal_registration && errors.municipal_registration)}
                      helperText={touched.municipal_registration && errors.municipal_registration}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                    <TextField
                      fullWidth
                      autoComplete="state_registration"
                      type="text"
                      label="Registro Estadual"
                      {...getFieldProps('state_registration')}
                      error={Boolean(touched.state_registration && errors.state_registration)}
                      helperText={touched.state_registration && errors.state_registration}
                    />
                    <TextField
                      fullWidth
                      autoComplete="address"
                      type="text"
                      label="Endereço"
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box marginTop={'20px'} justifyContent={'flex-end'} display={'flex'}>
                <Button onClick={handleClose} sx={{ mr: 2, color: '#4B4A64' }}>
                  Fechar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{ color: 'white', backgroundColor: '#009D81' }}
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </Button>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}
