import { ApiVersion, BaseService } from '~services/api'

export const GetConversationData = (id: string, Uid: string, access: string, client: string) => {
  return BaseService.get(`/shared_api/v1/conversations/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetConversationSubjets = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/shared_api/v1/conversation_subjects`, {
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllConversations = (
  Uid: string,
  access: string,
  client: string,
  pageInfo: { per_page: string; page: string },
  params: Record<string, string>,
) => {
  return BaseService.get(`/shared_api/v1/conversations?per_page=${pageInfo.per_page}&page=${pageInfo.page}`, {
    params,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const UpdateConversation = (id: string, Uid: string, access: string, client: string, data: any) => {
  return BaseService.put(
    `/shared_api/v1/conversations/${id}/`,
    { conversation: { status: data } },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const SendConversationMessage = (id: string, Uid: string, access: string, client: string, data: any) => {
  return BaseService.post(
    `/shared_api/v1/conversations/${id}/messages`,
    {
      message: { content: data },
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const OpenNewConversation = (Uid: string, access: string, client: string, data: any) => {
  return BaseService.post(
    `/shared_api/v1/conversations`,
    {
      conversation: data,
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const GetProcedures = (Uid: string, access: string, client: string, selectedDoctor: string, e: string) => {
  return BaseService.get(
    `/institution_api/v1/medical_productions?conversations=false&user_id=${selectedDoctor}&exactly_search_keys[]=performing_date&exactly_search_values[]=${e}`,
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}
