import { InputBase, styled } from '@mui/material'

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0,
  },
  '& .MuiInputBase-input': {
    borderRadius: '12px',
    position: 'relative',
    backgroundColor: '#E3E2EF',
    border: 0,
    fontSize: 16,
    padding: '16px 26px 16px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))
