import { Navigate } from 'react-router-dom'
import { useUserStore } from 'stores/users'

export type ProtectedRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
  outlet: JSX.Element
  routeName?: string
}

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
  routeName,
}: ProtectedRouteProps) {
  const User = useUserStore()
  if (
    (isAuthenticated && User.User.high_level_permissions.includes('institution_admin')) ||
    User.User.high_level_permissions?.includes(routeName.replace('/', '')) ||
    routeName === ''
  ) {
    return outlet
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />
  }
}
