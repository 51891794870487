import React from 'react'
import { Card, CardContent, Box, Typography, Icon } from '@mui/material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { random } from 'lodash'
import { colors } from '~constants/colorsPallete'
import { formatCurrency } from './MaisRealizados'

interface TopProcediments {
  value: number
  plano: string
  hospital: string
}

interface PrecoProcedimentoProps {
  procedimento: string
  isLess: boolean
  totalValue: number
  topProcediments: any
  [x: string]: any
}

export function PrecoProcedimento({
  procedimento,
  totalValue,
  topProcediments,
  isLess,
  ...rest
}: PrecoProcedimentoProps) {
  return (
    <Card
      sx={{
        backgroundColor: isLess ? colors.error : colors['gray.500'],
        margin: 'auto',
        width: '50%',
        borderRadius: 2,
        boxShadow: 6,
        marginBottom: 2,
        p: 1,
        flexDirection: 'column',
      }}
      key={random(0, 1000)}
      {...rest}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ArrowCircleDownIcon style={{ color: 'white' }} />
          <Typography variant="subtitle2" ml={2} color="white">
            {isLess ? 'MENORES PREÇOS POR PROCEDIMENTO' : 'MAIORES PREÇOS POR PROCEDIMENTO'}
          </Typography>
        </Box>

        <Box mt={1} display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color="white" variant="caption">
            Para o seu procedimento mais realizado
          </Typography>
          <Typography color={colors['green.light']} variant="h6" mb={2}>
            {procedimento}
          </Typography>

          <Box
            sx={{
              borderTop: 1,
              width: '60%',
              borderColor: isLess ? colors['gray.100'] : colors['gray.400'],
            }}
          />

          {topProcediments.map((item, index) => (
            <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" pt={3} width="100%">
              <Box width="70%" display="flex" flexDirection="column">
                <Typography variant="h6" color="white">
                  {item.plano}
                </Typography>
                <Typography color="white">{item.hospital}</Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography color="white" variant="body2">
                  Valor
                </Typography>
                <Typography color="white" variant="h6">
                  {formatCurrency(item.value / 100)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

function parseMoney(value, options) {
  // Implement your money parsing logic here
  return value
}
