import { colors } from './colorsPallete'

interface StatusInfo {
  bgcolor: string
  text: string
}

export const STATUS_MAPPING: { [key: string]: StatusInfo } = {
  Recebido: { bgcolor: colors.blue, text: 'Recebido' },
  Pago: { bgcolor: colors['green.default'], text: 'Pago' },
  'Não Recebido': { bgcolor: colors.error, text: 'Não Recebido' },
  'Outra Via': { bgcolor: colors['gray.600'], text: 'Outra Via' },
  Faturado: { bgcolor: colors['blue.light'], text: 'Faturado' },
  Glosado: { bgcolor: colors.warn, text: 'Glosado' },
  Atrasado: { bgcolor: '#FF1050', text: 'Atrasado' },
  Repassado: { bgcolor: colors['green.default'], text: 'Repassado' },
  REPASSADO: { bgcolor: colors['green.default'], text: 'Repassado' },
  'A Repassar': { bgcolor: colors.blue, text: 'A Repassar' },
  'Sem Regra': { bgcolor: colors['gray.600'], text: 'Sem Regra' },
  'Não Faturado': { bgcolor: colors.error, text: 'Não Faturado' },
}

export const getStatusInfo = (status?: string): StatusInfo => {
  return STATUS_MAPPING[status ?? 'default'] || STATUS_MAPPING['default']
}
