import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import img from '../../assets/images/logo_01.png'

const Logo = ({ width }) => {
  return (
    <Box>
      <Link to="/">
        <Box component="img" src={img} alt="logo" width={width} />
      </Link>
    </Box>
  )
}

export default Logo
