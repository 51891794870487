import { Card } from '@material-ui/core'
import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const CustomCard = styled(Card)`
  display: flex;
  box-shadow: 0px 34px 34px rgba(13, 46, 97, 0.05);
  border-radius: 15px;
  background-size: cover;
  margin-right: 20px;
  justify-content: space-between;
  height: 180px;
  background-color: #e3e2ef;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    padding: 10px;
    height: auto;
  }
`

export const OutsideBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;

  @media (max-width: 768px) {
    width: 90%; // or another suitable percentage
    margin: auto;
  }
`
export const Title = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 16px;
    white-space: nowrap;
    color: #4b4a64;
  }
`

export const TitleDif = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 32px;
    white-space: nowrap;
    color: #4b4a64;
  }
`

export const SubTitle = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    color: #000000;
  }
`

export const SubTitleValor = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 12px;
    margin-top: 34px;
    color: #000000;
  }
`

export const Value = styled(Typography)`
  && {
    font-size: 14px;
    color: #000000;
  }
`
export const LinearBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10px;
`

export const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 90%; // or another suitable percentage
    margin: auto;
    text-align: center;
  }
`
export const CustomTypography1 = styled(Typography)`
  && {
    font-size: 14px;
    color: #503094;
    padding: 0;
    margin: 0;
  }
`

export const CustomTypography2 = styled(Typography)`
  && {
    font-size: 12px;
    margin-top: 5px;
    color: #503094;
    padding: 0;
    margin: 0;
    font-weight: 300;
  }
`

export const CustomTypography3 = styled(Typography)`
  && {
    font-size: 12px;
    color: #503094;
    font-weight: 800;
  }
`
