import { Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { colors } from '~constants/colorsPallete'

interface RepasseProps {
  data: {
    value: number
    tipo: boolean
    date: string
  }
}

export default function Repasse(data: RepasseProps) {
  return (
    <Grid item xs={12} md={3}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              Próximos Repasses
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
              Data prevista:
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={700}>
              {data.data.date ? moment(data.data.date).format('DD/MM/YYYY') : 'Sem data prevista'}
            </Typography>
          </Grid>

          <Grid item lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300} textAlign={'right'}>
              Valor total
            </Typography>
            <Typography variant="h5" fontWeight={600} color={colors['gray.600']} textAlign={'right'}>
              {data.data.value?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
