import { ApiVersion, BaseService } from '~services/api'

export const GetUserInfo = (id: string, Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/institution_users/` + id, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserNotifications = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/shared_api/v1/notifications?sort_by=created_at&order_by=desc`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllInstitutionUsers = (Uid: string, access: string, client: string, data_source_hashid) => {
  return BaseService.get(`/shared_api/v1/institution_users/?data_source_id=${data_source_hashid}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const UpdateUser = (
  Uid: string,
  access: string,
  client: string,
  phone: string,
  email: string,
  name: string,
  id: string,
  permissions: string[],
) => {
  return BaseService.post(
    `/institution_api/v1/institution_user_permissions`,
    {
      institution_user_permission: {
        institution_user_hashid: id,
        high_level_permissions: permissions,
      },
    },
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const UpdateUserNotification = (id: string, Uid: string, access: string, client: string) => {
  return BaseService.put(
    `/shared_api/v1/notifications/${id}/`,
    {},
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const GetUsers = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/users?per_page=500`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}
