import { Grid, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { colors } from '~constants/colorsPallete'
import { LineChart } from '@mui/x-charts'

export default function ChartCard({ data, date, isLine }: any) {
  const [labels, setLabels] = useState([])
  const [values, setValues] = useState([])

  useEffect(() => {
    if (data) {
      setLabels(Object.keys(data) || [])
      setValues(Object.values(data) || [])
    }
  }, [data])

  return (
    <Grid item xs={12} md={6}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              {isLine ? 'Evolução do NPS' : 'Número de médicos atendidos mês a mês'}
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
              {isLine ? 'Mês a mês' : date ? date : 'Mês atual'}
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <div style={{ height: isLine ? '380px' : '320px', width: '100%' }}>
              <LineChart
                grid={{ vertical: true, horizontal: true }}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: -5,
                  },
                }}
                margin={{ top: 5, bottom: 60 }}
                // apenas round numbers no eixo y
                /*   yAxis={{
                  scaleType: 'linear',
                  tickInterval: 10,
                }} */
                series={[
                  {
                    data: isLine ? [-32, -20, 5, 13, 25, 8] : values,
                    label: isLine ? 'NPS' : 'Quantidade de médicos',
                    color: isLine ? colors['blue.light'] : colors.error,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'point',
                    data: isLine ? ['Jan/24', 'Fev/24', 'Mar/24', 'Abr/24', 'Mai/24', 'Jun/24'] : labels,
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
