import { Button, styled } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export const CustomButton = styled(Button)({
  backgroundColor: '#009D81',
  borderRadius: '6px',
  color: 'white',
  height: '40px',
  px: '20px',
  marginBottom: '20px',
  '&:hover': {
    backgroundColor: '#00cca6',
  },
})

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
export const checkedIcon = <CheckBoxIcon fontSize="small" />
