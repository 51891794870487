import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useUserStore } from 'stores/users'
import { DeletePushNotifications } from '~services/PushNotifications'
import { ColorButton, style } from './styled'

export default function ModalConfirm({
  handleOpen,
  handleClose,
  open,
  id,
  typeDelete,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  open: boolean
  id?: string
  typeDelete?: string
}) {
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleDeletePush = () => {
    DeletePushNotifications(Uid, access, client, id, typeDelete)
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Excluir Item
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Você tem certeza que deseja excluir esse item?
        </Typography>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose} sx={{ mr: 2, color: '#4B4A64' }}>
            Fechar
          </Button>
          <ColorButton variant="contained" onClick={() => handleDeletePush()}>
            Excluir
          </ColorButton>
        </div>
      </Box>
    </Modal>
  )
}
