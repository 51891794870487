import { Card, Grid, Typography } from '@mui/material'
import { colors } from '~constants/colorsPallete'
import { LineChart } from '@mui/x-charts'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export default function LogChart({ data, title }: { data: any; title: string }) {
  const [month, setMonth] = useState<string[]>([])
  const [value, setValue] = useState<any[]>([])

  useEffect(() => {
    const newMonths: string[] = []
    const newValues: any[] = []

    Object.entries(data[0]).forEach(([key, values]) => {
      newMonths.push(dayjs(key).format('MMMM/YYYY'))
      newValues.push(values)
    })

    setMonth(newMonths)
    setValue(newValues)
  }, [data])

  return (
    <Card
      sx={{
        width: '50%',
        margin: 'auto',
        marginBottom: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 2,
        boxShadow: 6,
        backgroundColor: 'white',
        px: 1,
      }}
    >
      <Grid container p={2}>
        <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
          <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
            {title}
          </Typography>
          <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
            {'Aplicativo Seiwa'}
          </Typography>
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div style={{ height: '380px', width: '100%' }}>
            <LineChart
              grid={{ vertical: true, horizontal: true }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { vertical: 'bottom', horizontal: 'middle' },
                  padding: -5,
                },
              }}
              xAxis={[
                {
                  scaleType: 'point',
                  data: month,
                },
              ]}
              series={[{ data: value, label: 'Quantidade de acessos' }]}
              margin={{ top: 5, bottom: 60 }}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  )
}
