import { Grid, Paper, Typography } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { colors } from '~constants/colorsPallete'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function SatisfactionCard(data: any, allValues: any) {
  const datas = {
    labels: ['Detratores', 'Neutros', 'Promotores'],
    datasets: [
      {
        label: 'Índice de Satisfação dos Médicos',
        data: [data.data[0], data.data[1], data.data[2]],
        backgroundColor: ['#E96338', '#E4BE34', '#009D81'],
      },
    ],
  }

  const detratores = data.data[0]
  const neutro = data.data[1]
  const promotores = data.data[2]

  const total = detratores + neutro + promotores

  const percentualPromotores = (promotores / total) * 100
  const percentualDetratores = (detratores / total) * 100

  const score = percentualPromotores - percentualDetratores

  const scoreLabor = (score) => {
    if (score > 74) {
      return (
        <Typography variant="subtitle1" color={colors['green.default']} fontWeight={500} mt={1} mb={2}>
          Excelente
        </Typography>
      )
    }
    if (score > 49 && score < 75) {
      return (
        <Typography variant="subtitle1" color={colors.blue} fontWeight={500} mt={1} mb={2}>
          Muito Bom
        </Typography>
      )
    }
    if (score > 0 && score < 50) {
      return (
        <Typography variant="subtitle1" color={colors.warn} fontWeight={500} mt={1} mb={2}>
          Razóavel
        </Typography>
      )
    } else
      return (
        <Typography variant="subtitle1" color={colors.error} fontWeight={500} mt={1} mb={2}>
          Ruim
        </Typography>
      )
  }

  return (
    <Grid item xs={12} md={6}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              Índice de Satisfação dos Médicos
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
              NPS
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xs={12}
            md={12}
            style={{
              height: 320,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Doughnut
              data={datas}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'bottom' as const,
                    display: true,
                  },
                },
              }}
            />
            <Typography
              variant="h6"
              color={colors['gray.500']}
              fontWeight={500}
              style={{ position: 'absolute', marginTop: '-100px' }}
            >
              {score.toFixed(0)}
            </Typography>
            {scoreLabor(score.toFixed(0))}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
