export const options = [
  { value: 'institution_admin', label: 'Administrador' },
  { value: 'dashboard_page', label: 'Dashboard' },
  { value: 'conversations_page', label: 'Chamados' },
  { value: 'medical_productions_page', label: 'Produções Médicas' },
  { value: 'push_notifications_page', label: 'Push Notificações' },
  { value: 'companies_page', label: 'Empresas' },
  { value: 'invoices_page', label: 'Notas Fiscais' },
  { value: 'scores_page', label: 'Avaliações' },
]
