import Box from '@mui/material/Box'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { colors } from '~constants/colorsPallete'
import DashboardIcon from '@mui/icons-material/Dashboard'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import NotificationsIcon from '@mui/icons-material/Notifications'
import WorkIcon from '@mui/icons-material/Work'
import ReceiptIcon from '@mui/icons-material/Receipt'
import StarIcon from '@mui/icons-material/Star'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/users'
import { Tooltip } from '@material-ui/core'
import { Typography } from '@mui/material'
import CheckAccess from 'hooks/useRouter'
import { Drawer, DrawerHeader } from './styled'
import SmartphoneIcon from '@mui/icons-material/Smartphone'

export default function MiniDrawer() {
  const { openDrawer, setOpenDrawer } = useUserStore()
  const navigate = useNavigate()
  const location = useLocation()
  const currentUrl = location.pathname
  const dashboard = location.state?.from?.pathname || '/dashboard_page'
  const inconformidadesRoute = location.state?.from?.pathname || '/conversations_page'
  const producoesRoute = location.state?.from?.pathname || '/medical_productions_page'
  const pushs = location.state?.from?.pathname || '/push_notifications_page'
  const allDoctors = location.state?.from?.pathname || '/companies_page'
  const nfs = location.state?.from?.pathname || '/invoices_page'
  const avaliacao = location.state?.from?.pathname || '/scores_page'
  const medicalVisualization = location.state?.from?.pathname || '/mobileUserVisualization_page'
  const setFilter = useUserStore((state) => state.setFilter)
  const menuItems = [
    { route: dashboard, icon: <DashboardIcon />, title: 'Dashboard' },
    { route: inconformidadesRoute, icon: <QuestionAnswerIcon />, title: 'Chamados' },
    { route: producoesRoute, icon: <ContentPasteIcon />, title: 'Produção' },
    { route: pushs, icon: <NotificationsIcon />, title: 'Push Notifications' },
    { route: allDoctors, icon: <WorkIcon />, title: 'Empresas' },
    { route: nfs, icon: <ReceiptIcon />, title: 'Notas Fiscais' },
    { route: avaliacao, icon: <StarIcon />, title: 'Avaliações' },
    { route: medicalVisualization, icon: <SmartphoneIcon />, title: 'Visualização do Médico' },
  ]

  return (
    <Box>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={openDrawer}
        PaperProps={{
          sx: {
            border: 'none',
            backgroundColor: colors['green.default'],
          },
        }}
      >
        <DrawerHeader>
          <IconButton
            sx={{
              color: '#fff',
              margin: !openDrawer ? 'auto' : 'none',
            }}
            onClick={() => {
              setOpenDrawer(!openDrawer)
            }}
          >
            {!openDrawer ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          {menuItems.map((text) => {
            return CheckAccess(text.route) ? (
              <ListItem key={text.route} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() => {
                    setFilter('')
                    navigate(text.route, { replace: true })
                  }}
                  sx={{
                    color: currentUrl === text.route ? colors['green.default'] : 'white',
                    backgroundColor: currentUrl === text.route ? 'white' : 'transparent',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <Tooltip title={<Typography variant="subtitle1">{text.title}</Typography>} placement="right" arrow>
                    <ListItemIcon
                      sx={{
                        color: currentUrl === text.route ? colors['green.default'] : '#fff',
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          })}
        </List>
      </Drawer>
    </Box>
  )
}
