import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Divider, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import ModalConfirmDoc from '../modalConfirmDoc'
import { toast, ToastContainer } from 'react-toastify'
import 'dayjs/locale/pt-br'
import { style } from './styled'
import { useUserStore } from 'stores/users'
import { ListInvoiceObservations, PostInvoiceObservations } from '~services/Receipts'
import { MoonLoader } from 'react-spinners'
import { override } from 'pages/PushNotificationPage'

export default function ModalCommentNfs({
  handleClose,
  open,
  id,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  open: boolean
  id: string
}) {
  const [openSucess, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [loadingData, setLoadingData] = React.useState(false)
  const [data, setData] = React.useState<any>([])
  const [observation, setObservation] = React.useState('')
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleOpen = () => {
    handleClose()
    setOpen(true)
  }
  const handleDismiss = () => setOpen(false)

  useEffect(() => {
    setLoadingData(true)
    ListInvoiceObservations(Uid, access, client, id)
      .then((response) => {
        setData(response.data.data)
        setLoadingData(false)
      })
      .catch((error) => {
        setLoadingData(false)
        console.log(error)
      })
  }, [id])

  const submitObservation = () => {
    setLoading(true)
    PostInvoiceObservations(Uid, access, client, id, observation)
      .then(() => {
        ListInvoiceObservations(Uid, access, client, id).then((response) => setData(response.data.data))
        setObservation('')
        setLoading(false)
        toast.success('Observação enviada com sucesso!')
      })
      .catch((error) => {
        console.log(error)
        toast.error('Ooops! Algo deu errado ao enviar sua observação.')
      })
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ModalConfirmDoc handleOpen={handleOpen} handleClose={handleDismiss} open={openSucess} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" component="h2">
            Observação na Nota fiscal
          </Typography>
          <Typography variant="subtitle1" component="h2">
            Escreva com detalhes o motivo da recusa da nota fiscal.
          </Typography>
          {loadingData && (
            <Box alignContent={'center'} justifyContent={'center'} py={4} margin={'auto'}>
              <MoonLoader
                color={'#009d81'}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={override}
              />
            </Box>
          )}
          {data.length !== 0 && (
            <>
              <Typography variant="subtitle2" component="h2" mt={3} mb={1}>
                Observações anteriores:
              </Typography>
              {data.map((item: any, index) => (
                <>
                  <Typography variant="body1" component="h2" my={1}>
                    {item.attributes.description}
                  </Typography>
                  {index !== data.length - 1 && <Divider />}
                </>
              ))}
            </>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                <TextField
                  fullWidth
                  multiline
                  type="text"
                  label="Observação"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={'20px'} justifyContent={'flex-end'} display={'flex'}>
            <Button
              onClick={() => {
                setData([])
                setObservation('')
                handleClose()
              }}
              sx={{ mr: 2, color: '#4B4A64' }}
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              disabled={loading || observation === ''}
              onClick={submitObservation}
              sx={{ color: 'white', backgroundColor: '#009D81' }}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
