import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { UserState, HospitalUser } from './users.interfaces'

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        User: {
          data_source: '',
          data_source_hashid: '',
          email: '',
          email_confirmed_at: '',
          first_name: '',
          last_name: '',
          legal_agreement: false,
          phone: '',
          unconfirmed_email: '',
          unconfirmed_phone: '',
          high_level_permissions: [],
        },
        tokenSheet: null,
        id: '',
        type: '',
        filter: '',
        isLogged: false,
        token: '',
        client: '',
        phone: '',
        refreshToken: '',
        openDrawer: false,
        openNotification: false,
        notificationMessageToken: null,
        clearUser: () =>
          set(() => ({
            refreshToken: '',
            tokenSheet: null,
            notificationMessageToken: null,
            User: {
              high_level_permissions: [],
              data_source: '',
              email: '',
              email_confirmed_at: '',
              first_name: '',
              last_name: '',
              legal_agreement: false,
              phone: '',
              unconfirmed_email: '',
              unconfirmed_phone: '',
            },
          })),
        setOpenDrawer: (state) => set(() => ({ openDrawer: state })),
        setOpenNotification: (state) => set(() => ({ openNotification: state })),
        setNotificationMessageToken: (token) => set(() => ({ notificationMessageToken: token })),
        setTokenSheet: (token) => set(() => ({ tokenSheet: token })),
        setClient: (client) => set(() => ({ client: client })),
        setId: (id) => set(() => ({ id: id })),
        setType: (type) => set(() => ({ type: type })),
        setUser: (user: HospitalUser) => set(() => ({ User: user })),
        setFilter: (filter) => set(() => ({ filter: filter })),
        setToken: (token) => set(() => ({ token: token })),
        setPhone: (phone) => set(() => ({ phone: phone })),
        setRefreshToken: (refreshToken) => set(() => ({ refreshToken: refreshToken })),
        setLogged: (state) => set(() => ({ isLogged: state })),
      }),
      {
        name: 'UserStore',
      },
    ),
  ),
)
