import { Box, Typography } from '@mui/material'
import { colors } from '~constants/colorsPallete'
import noData from '~assets/images/noData.png'

function NoData({ isMobileView, isLogs }: { isMobileView?: boolean; isLogs?: boolean }) {
  // Determine the message based on the conditions
  let message
  if (isMobileView) {
    message = 'Selecione um médico para visualizar as informações.'
  } else if (isLogs) {
    message = 'Ops! Ainda não possuimos informações desse usuário.'
  } else {
    message = 'Ops! Não encontramos dados para o período selecionado.'
  }

  return (
    <Box display="flex" alignContent="center" justifyContent="center" textAlign="center" flexDirection="column">
      <Box
        mt={5}
        width={300}
        height={300}
        display="flex"
        alignContent="center"
        justifyContent="center"
        style={{
          backgroundColor: colors.white,
          padding: 5,
          borderRadius: 200,
          alignSelf: 'center',
          justifySelf: 'center',
        }}
      >
        <img src={noData} width={200} height={200} style={{ margin: 'auto' }} alt="No Data" />
      </Box>

      <Typography variant="h5" fontWeight="500" mt={isLogs ? 0 : 4}>
        {message}
      </Typography>

      {!isLogs && (
        <Typography variant="subtitle1" fontWeight="300">
          Tente novamente ou contate a <b style={{ color: colors['green.default'], fontWeight: 500 }}>Seiwa</b> para
          mais informações.
        </Typography>
      )}
    </Box>
  )
}

export default NoData
