import { BaseService } from '../api'

export const uploadFile = async (file: any, Uid: string, access: string, client: string) => {
  const paths = file.path.split('/')
  const file_name = paths[paths.length - 1]
  const { data } = await BaseService.post('shared_api/s3-sign', null, {
    params: { file_name },
    headers: {
      Client: client,
      Uid: Uid,
      'Access-Token': access,
    },
  })

  await fetch(data.data.presigned_aws_url, {
    method: 'PUT',
    body: file,
  })

  return data.data.presigned_aws_url?.split('?')?.[0]
}
