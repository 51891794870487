import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { Box, IconButton, TablePagination } from '@mui/material'
import { BoxRow, Cell, ChipAlert, ChipSucess, Date, DateHour, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import ModalGroupDetails from '~components/modais/modalGroupDetails'
import moment from 'moment'
import { colors } from '~constants/colorsPallete'
import ModalEditPush from '~components/modais/modalEditPush'
import dayjs from 'dayjs'
import { CustomTable } from '../conversationsTable/styled'
import { MoonLoader } from 'react-spinners'
import { override } from 'pages/PushNotificationPage'
import NoData from '~containers/noData'
import { PushNotificationsTableProps } from './type'

export default function PushNotificationsTable({
  data,
  dasy,
  onFilterChange,
  count,
  doctorsList,
  loading,
}: PushNotificationsTableProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [rowData, setRowData] = React.useState<any>([])
  const [openGroup, setOpenGroup] = React.useState(false)
  const [group, setGroup] = React.useState<string[]>()
  const [deleteId, setDeleteId] = React.useState<string>()
  const [openEdidComunicacao, setOpenEditComunicacao] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenGroup = () => setOpenGroup(true)
  const handleCloseGroup = () => setOpenGroup(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  React.useEffect(() => {
    onFilterChange({ page: page + 1, per_page: rowsPerPage })
  }, [page, rowsPerPage])

  return (
    <>
      {loading ? (
        <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
          <MoonLoader
            color={'#009d81'}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
          />
        </Box>
      ) : data.length === 0 ? (
        <NoData />
      ) : (
        <CustomTable>
          {dasy === true ? null : <Title>Todas as Notificações</Title>}

          <TableMain>
            <TableHead>
              <TableRow>
                <Cell align="center">Título</Cell>
                <Cell align="center">Destinatários</Cell>
                {dasy !== true ? <Cell>Descrição</Cell> : null}

                <Cell align="center">Data</Cell>
                <Cell align="center">Status</Cell>
                <Cell align="center">Editar</Cell>
                {dasy !== true ? <Cell align="center">Deletar</Cell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .sort(
                  (a, b) => moment(b.attributes.schedule_time).valueOf() - moment(a.attributes.schedule_time).valueOf(),
                )
                .map((row: any, index: number) => (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      {row.attributes?.title}
                    </TableCell>
                    {row.attributes.users.length > 1 ? (
                      <TableCell
                        align="center"
                        onClick={() => {
                          setGroup(row.attributes.users)
                          handleOpenGroup()
                        }}
                      >
                        Diversos
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.attributes.users[0]?.name}</TableCell>
                    )}
                    {dasy !== true ? <TableCell align="left">{row.attributes?.body}</TableCell> : null}

                    <TableCell align="center">
                      <BoxRow>
                        <Date>{moment(row.attributes?.schedule_time).format('DD/MM/YYYY')}</Date>
                        <DateHour>{moment(row.attributes?.schedule_time).format('hh:mm a')}</DateHour>
                      </BoxRow>
                    </TableCell>
                    {dasy !== true ? (
                      <TableCell align="center">
                        {row.attributes?.schedule_status === 'scheduled' ? (
                          <ChipAlert size="small" label={'PLANEJADO'} />
                        ) : (
                          <ChipSucess size="small" label={'ENVIADO'} />
                        )}
                      </TableCell>
                    ) : null}

                    <TableCell align="center">
                      <IconButton
                        aria-label="edit"
                        disabled={row.attributes?.schedule_status !== 'scheduled'}
                        onClick={() => {
                          setRowData(row)
                          setOpenEditComunicacao(true)
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          sx={{
                            color: row.attributes?.schedule_status === 'scheduled' ? colors.warn : colors['gray.100'],
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    {dasy !== true ? (
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setDeleteId(row.id)
                            handleOpen()
                          }}
                        >
                          <DeleteIcon fontSize="small" sx={{ color: '#E96338' }} />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </TableMain>
          <TablePagination
            labelRowsPerPage="Itens por página"
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CustomTable>
      )}
      <ModalEditPush
        handleClose={() => setOpenEditComunicacao(false)}
        open={openEdidComunicacao}
        selectedDoctors={rowData?.attributes?.users}
        doctors={doctorsList}
        selectedMessage={rowData?.attributes?.body}
        selectedTitle={rowData?.attributes?.title}
        date={dayjs(rowData?.attributes?.schedule_time)}
        updatePage={true}
        notificationId={rowData.id}
      />
      <ModalGroupDetails handleOpen={handleOpenGroup} handleClose={handleCloseGroup} open={openGroup} text={group} />
      <ModalConfirm
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        typeDelete="/institution_api/v1/push_notifications/"
        id={deleteId}
      />
    </>
  )
}
