import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, Grid, TextField } from '@mui/material'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useUserStore } from 'stores/users'
import React from 'react'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo/DemoContainer'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'

import { colors } from '~constants/colorsPallete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import moment from 'moment'
import { ptBR } from '@mui/x-date-pickers/locales'
import { EditPushNotifications } from '~services/PushNotifications'
import { LoginSchema } from 'schemas'
import { checkedIcon, icon } from '~utils/styles'
import { CustomInput, style } from './styled'
moment.locale('pt-br')

export default function ModalEditPush({
  handleClose,
  open,
  doctors,
  date,
  selectedDoctors,
  selectedTitle,
  selectedMessage,
  updatePage,
  notificationId,
}: {
  handleClose: any
  open: boolean
  doctors: any
  date: Dayjs
  selectedDoctors: any
  selectedTitle: string
  selectedMessage: string
  updatePage: boolean
  notificationId: string
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [message, setMessage] = React.useState(selectedMessage ?? '')
  const [sendNow, setSendNow] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [selectedDoctor, setSelectedDoctor] = React.useState('')
  const [selectedData, setSelectedData] = React.useState('')
  const [selectedProcedimento, setSelectedProcedimento] = React.useState<any>()

  const filterMedicBySelected = (doctor, selectedDoctor) => {
    return doctors?.filter((doctor) => {
      const returnValue = selectedDoctors?.find((selectedDoctor) => {
        return selectedDoctor.hashid == doctor.id
      })
      return returnValue
    })
  }
  useEffect(() => {
    if (selectedMessage) {
      setMessage(selectedMessage)
      selectedMessage = null
    }
    if (selectedTitle) {
      setTitle(selectedTitle)
      selectedTitle = null
    }
    if (selectedDoctors && doctors) {
      //setSelectedProcedimento(filterMedicBySelected(doctors, selectedDoctors))
    }
    if (date) {
      setSelectedData(date.format('YYYY-MM-DDTHH:mm:ssZ[Z]'))
    }
  }, [selectedMessage, selectedTitle, selectedDoctors, selectedDoctors, date])

  const submitPush = () => {
    const data = {
      title: title,
      body: message,
      schedule_time: selectedData,
      user_hashids:
        selectedProcedimento == null || selectedProcedimento == undefined || selectedProcedimento == ''
          ? selectedDoctors.map((item: { hashid: string }) => item.hashid)
          : selectedProcedimento,
    }
    EditPushNotifications(Uid, access, client, notificationId, data)
      .then((response) => {
        toast.success('Push editado com sucesso!')
        setTimeout(() => window.location.reload(), 3000)
      })
      .catch((error) => {
        console.log(error)
        toast.error('Ooops, algo deu errado! Tente novamente.')
      })
  }
  const formik = useFormik({
    initialValues: {
      email: 'test@gmail.com',
      password: '123456',
      submit: null,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      console.log('hey')
    },
  })

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box sx={style} width={isMobile ? '80%' : '50%'}>
            <Typography variant="h5" component="h2">
              Editar push notification
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
                  Edite essa campanha de envio de push notifications.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid item ml={4} mt={1}>
                    {doctors && (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={doctors}
                        onChange={(event: any, newValue: any) => {
                          setSelectedProcedimento(newValue.map((item: { id: string }) => item.id))
                        }}
                        disableCloseOnSelect
                        defaultValue={filterMedicBySelected(doctors, selectedDoctors)}
                        getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.attributes.name}
                          </li>
                        )}
                        style={{ width: 270 }}
                        renderInput={(params: any) => <TextField {...params} label="Médicos" placeholder="Médicos" />}
                      />
                    )}
                  </Grid>
                  <Grid item ml={4} mt={2}>
                    <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
                      Programe seu envio ou envie imediatamente.
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                      <DemoContainer components={['DateTimePicker']}>
                        <MobileDateTimePicker
                          orientation="landscape"
                          views={['year', 'month', 'day', 'hours', 'minutes']}
                          disablePast
                          disabled={sendNow}
                          localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                          value={date}
                          onChange={(e: any) => {
                            setSelectedData(dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ[Z]'))
                            date = e
                          }}
                          onError={(e: any) => {
                            console.log('ERROR')
                            console.log(e)
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <Button
                      variant="contained"
                      startIcon={sendNow ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      onClick={() => {
                        const currentTime = moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ[Z]').toString()
                        setSelectedData(currentTime)
                        setSendNow(!sendNow)
                      }}
                      sx={{
                        mt: 3,
                        backgroundColor: sendNow ? '#009D81' : colors['gray.200'],
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: sendNow ? '#009D81' : colors['gray.200'],
                        },
                      }}
                    >
                      Enviar agora
                    </Button>
                  </Grid>
                </Grid>

                <CustomInput
                  fullWidth
                  placeholder="Titulo"
                  sx={{ mt: 2 }}
                  value={title}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                />
                <CustomInput
                  fullWidth
                  placeholder="Mensagem"
                  multiline
                  sx={{ mt: 2 }}
                  inputProps={{ maxLength: 120 }}
                  value={message}
                  defaultValue={selectedMessage}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '20px',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Button
                onClick={() => {
                  handleClose()
                  setSelectedDoctor('')
                  setSelectedData('')
                  setSelectedProcedimento('')
                  setMessage('')
                  setTitle('')
                }}
                sx={{ mr: 3, color: '#984A4A' }}
              >
                Cancelar
              </Button>
              <Button type="submit" sx={{ m: 3, color: '#009D81' }} onClick={() => submitPush()}>
                Registrar
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </>
  )
}
