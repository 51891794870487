import { Grid, Paper, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from '~constants/colorsPallete'

export default function BigCard(data: any) {
  const navigate = useNavigate()
  const location = useLocation()

  const nfs = location.state?.from?.pathname || '/nfs'

  return (
    <Grid item xs={12} md={2}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700} lineHeight={1.3}>
              Notas Fiscais solicitadas ainda não recebidas
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <Typography variant="h4" fontWeight={600} color={colors.error} textAlign={'right'}>
              {data?.data}
            </Typography>
            <Typography
              variant="subtitle2"
              color={colors['green.default']}
              fontWeight={500}
              textAlign={'right'}
              onClick={() => navigate(nfs, { replace: true })}
            >
              Conferir
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
