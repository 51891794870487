import { Grid, LinearProgress, Paper, Typography, linearProgressClasses, styled } from '@mui/material'
import moment from 'moment'
import { colors } from '~constants/colorsPallete'
import { LinearBox } from './styled'

interface AmountCardProps {
  date: any
  lateValue: number
  actualDate: string
  valueRepasse: number
  valuePrevisto: number
  barValue: number
}

export default function AmountCard(data: AmountCardProps) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors['green.default'],
    },
  }))

  return (
    <Grid item xs={12} md={5}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              Repasses previstos
            </Typography>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300}>
              {data.actualDate
                ? `Valores Previstos para ${moment(data.actualDate).format('MMMM/YYYY')}`
                : `Valores Previstos para ${moment().format('MMMM/YYYY')}`}
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12} mb={2}>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300} textAlign={'left'}>
              Atrasado acumulado (Bruto)
            </Typography>
            <Typography variant="h5" fontWeight={600} color={colors.error}>
              {data.lateValue?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xs={6} md={6}>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300} textAlign={'left'}>
              Repasse previsto (Bruto)
            </Typography>
            <Typography variant="h5" fontWeight={600} color={colors['gray.600']}>
              {data.valuePrevisto?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xs={6} md={6}>
            <Typography variant="subtitle2" color={colors['gray.500']} fontWeight={300} textAlign={'right'}>
              Pago (Líquido)
            </Typography>
            <Typography variant="h5" fontWeight={600} color={colors['gray.600']} textAlign={'right'}>
              {data.valueRepasse?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <LinearBox>
              <BorderLinearProgress variant="determinate" value={data.barValue} sx={{ backgroundColor: 'red' }} />
            </LinearBox>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
