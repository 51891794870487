import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'

export default function PendentResponses(data: any) {
  const navigate = useNavigate()
  const location = useLocation()
  const setFilter = useUserStore((state) => state.setFilter)
  const inconformidadesRoute = location.state?.from?.pathname || '/conversations_page'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = (filterName: string) => {
    setFilter(filterName)
    navigate(inconformidadesRoute, { replace: true })
  }

  return (
    <Grid item xs={12} md={6}>
      <Paper variant="outlined" square={false}>
        <Grid container p={2}>
          <Grid item mb={3} lg={12} sm={12} xs={12} md={12}>
            <Typography variant="subtitle1" color={colors['gray.500']} fontWeight={700}>
              Chamados com respostas pendentes
            </Typography>
          </Grid>
          {['Sem Regra', 'Outra Via', 'Repassado', 'A Repassar'].map((item, idx) => (
            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              key={idx}
              justifyContent={isMobile ? 'center' : 'flex-start'}
              sx={{ textAlign: 'center', borderRight: idx !== 3 ? 1 : 0, borderColor: '#C5C7CD' }}
            >
              <Box p={1} onClick={() => handleClick(item)} style={{ cursor: 'pointer' }}>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  {item}
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  {data.data?.[item] || 0}
                </Typography>
              </Box>
            </Grid>
          ))}
          {['Glosado', 'Não Recebido', 'Não Faturado'].map((item, idx) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              lg={4}
              key={idx}
              justifyContent={isMobile ? 'center' : 'flex-start'}
              sx={{ textAlign: 'center', borderRight: idx !== 2 ? 1 : 0, borderColor: '#C5C7CD' }}
            >
              <Box p={1} onClick={() => handleClick(item)} style={{ cursor: 'pointer' }}>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  {item}
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  {data.data?.[item] || 0}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  )
}
