import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid } from '@mui/material'
import { CustomInput } from '../modalEditPush/styled'
import { style } from './styled'

export default function ModalNfs({ handleClose, open, revisao }: { handleClose: any; open: boolean; revisao: string }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" component="h2">
          Revisão de
          <br />
          Nota Fiscal
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
              Adicionar nova Revisão de Nota Fiscal
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant="h6" sx={{ mt: 2, ml: 4, color: '#696969' }}>
              Revisões anteriores
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, ml: 4, color: '#696969' }}>
              {revisao}
            </Typography>
            <Typography variant="body2" sx={{ mt: 4, ml: 4, color: '#696969' }}>
              Adicionar nova Revisão de Nota Fiscal
            </Typography>
            <CustomInput fullWidth placeholder="Descrição da Revisão" multiline />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose} sx={{ mr: 1, color: '#984A4A' }}>
            Cancelar
          </Button>
          <Button onClick={handleClose} sx={{ mr: 3, color: '#009D81' }}>
            Registrar
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
