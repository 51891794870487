import { Box, Typography } from '@mui/material'
import { override } from 'pages/PushNotificationPage'
import { MoonLoader } from 'react-spinners'

export const LoadingIndicator = () => (
  <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
    <MoonLoader color={'#009d81'} size={50} aria-label="Loading Spinner" data-testid="loader" cssOverride={override} />
    <Typography variant="h6" component="div" mt={2} sx={{ flexGrow: 1 }} textAlign={'center'}>
      Carregando informações...
    </Typography>
  </Box>
)

export const LoadingIndicatorHeader = () => (
  <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
    <MoonLoader color={'#009d81'} size={50} aria-label="Loading Spinner" data-testid="loader" cssOverride={override} />
    <Typography variant="h6" component="div" mt={2} sx={{ flexGrow: 1 }} textAlign={'center'}>
      Carregando Valores...
    </Typography>
  </Box>
)
