import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import ModalConfirmDoc from '../modalConfirmDoc'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'
import { useUserStore } from 'stores/users'
import { useDropzone } from 'react-dropzone'
import { Container } from '~components/dropzone'
import 'dayjs/locale/pt-br'
import { uploadFile } from '~services/S3'
import { SendSlackMessage } from '~services/Slack'
import { CreateNfs, InvoiceNotify } from '~services/Receipts'
import { CompanySchema } from 'schemas'
import { style } from './styled'
import { checkedIcon, icon } from '~utils/styles'
import { colors } from '~constants/colorsPallete'

export default function ModalAddNewNfs({
  handleClose,
  open,
  isDoc,
  doctors,
  users,
  isCreate,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  isDoc: boolean
  open: boolean
  users?: any
  isCreate: boolean
  doctors?: any
}) {
  const [openSucess, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [date, setDate] = React.useState('')
  const [selectedDoctor, setSelectedDoctor] = React.useState<any>('')
  const [isBusiness, setIsBusiness] = React.useState(true)
  const [doctorsSolicited, setDoctorsSolicited] = React.useState<any>([])
  const [acceptedFile, setAcceptedFile] = React.useState<any>()
  const Uid = useUserStore((state) => state.phone)
  const id = useUserStore((state) => state.id)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const selectedDoctorOption = users?.filter((option) => selectedDoctor?.includes(option.id))
  const selectedBusinessOption = doctors?.filter((option) => doctorsSolicited?.includes(option.id))

  const handleOpen = () => {
    handleClose()
    setOpen(true)
  }
  const handleDismiss = () => setOpen(false)

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      invoiceNumber: '',
      fantasia: '',
    },
    validationSchema: CompanySchema,

    onSubmit: () => {
      setLoading(true)
      uploadFile(acceptedFile, Uid, access, client).then((res) => {
        const data = {
          corporate_name: formik.values.fantasia,
          invoice_date: date,
          pdf_file_url: res,
          institution_user_id: id,
          received_status: 1,
          verification_status: 0,
          cnpj: formik.values.cnpj,
          invoice_number: formik.values.invoiceNumber,
          user_id: selectedDoctor.id,
        }
        CreateNfs(Uid, access, client, data)
          .then(() => {
            toast.success('Nota fiscal cadastrada com sucesso!')
            setLoading(false)
            setTimeout(() => window.location.reload(), 3000)
          })
          .catch((error) => {
            console.log(error)
            SendSlackMessage(
              `/shared_api/v1/invoices`,
              error,
              {
                invoice: data,
              },
              Uid,
              'Post',
            )
            setLoading(false)
            toast.error('Ooops, algo deu errado! Tente novamente.')
          })
      })
    },
  })

  const handleSend = () => {
    InvoiceNotify(Uid, access, client, doctorsSolicited, selectedDoctor, isBusiness)
      .then(() => {
        toast.success('Solicitação realizada com sucesso!')
        setLoading(false)
        setTimeout(() => window.location.reload(), 3000)
      })
      .catch((error) => {
        console.log(error)
        /*   SendSlackMessage(
          `/institution_api/v1/invoice_instructions/notify`,
          error,
          {
            user_ids: doctorsSolicited,
          },
          Uid,
          'Post',
        ) */
        setLoading(false)
        toast.error('Ooops, algo deu errado! Tente novamente.')
      })
  }

  const { errors, touched, handleSubmit, getFieldProps } = formik

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setAcceptedFile(acceptedFiles[0])
    },
  })

  const acceptedFileItems = acceptedFiles.map((file: any) => <li key={file.path}>{file.path}</li>)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBusiness(event.target.checked)
    setDoctorsSolicited(null)
    setSelectedDoctor(null)
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ModalConfirmDoc handleOpen={handleOpen} handleClose={handleDismiss} open={openSucess} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={style} width={isCreate ? '60%' : '30%'}>
              <Typography variant="h5" component="h2">
                {isCreate ? 'Anexar nova Nota Fiscal' : 'Solicitar Nota Fiscal'}
              </Typography>
              {isCreate ? (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="container">
                      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />
                        <p>Arraste ou clique para adicionar novos arquivos</p>
                      </Container>
                      <aside>
                        {acceptedFiles.length > 0 ? (
                          <>
                            {' '}
                            <Typography variant="h6" mt={4}>
                              Nota adicionada
                            </Typography>
                            <ul>{acceptedFileItems}</ul>{' '}
                          </>
                        ) : null}
                      </aside>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                      <TextField
                        fullWidth
                        autoComplete="Nome Fantasia"
                        type="text"
                        label="Nome Fantasia"
                        {...getFieldProps('fantasia')}
                        error={Boolean(touched.fantasia && errors.fantasia)}
                        helperText={touched.fantasia && errors.fantasia}
                      />
                      <TextField
                        fullWidth
                        autoComplete="CNPJ"
                        type="number"
                        label="CNPJ"
                        {...getFieldProps('cnpj')}
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                      />
                      <TextField
                        fullWidth
                        autoComplete="invoiceNumber"
                        type="text"
                        label="Número da nota fiscal"
                        {...getFieldProps('invoiceNumber')}
                        error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                        helperText={touched.invoiceNumber && errors.invoiceNumber}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="Data de realização"
                            format="DD/MM/YYYY"
                            defaultValue={dayjs()}
                            onChange={(e) => {
                              setDate(dayjs(e).format('DD/MM/YYYY'))
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        options={doctors}
                        onChange={(event: any, newValue: any) => {
                          setSelectedDoctor(newValue)
                        }}
                        getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.attributes.name}
                          </li>
                        )}
                        style={{ width: '100%' }}
                        renderInput={(params: any) => (
                          <TextField {...params} label="A quem a Nota Fiscal pertence" placeholder="Médicos" />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12} my={2}>
                    <Typography variant="subtitle1" component="h2" mb={1}>
                      Selecione para quem realizar a solicitação:
                    </Typography>
                    <Box display="flex" flexDirection="row" alignContent={'center'} justifyContent={'center'} mb={3}>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        mt={'5px'}
                        sx={{
                          color: !isBusiness ? colors['green.default'] : colors['gray.500'],
                          fontWeight: !isBusiness ? 500 : 300,
                        }}
                      >
                        Médicos
                      </Typography>
                      <Switch defaultChecked checked={isBusiness} onChange={handleChange} />
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        mt={'5px'}
                        sx={{
                          color: isBusiness ? colors['green.default'] : colors['gray.500'],
                          fontWeight: isBusiness ? 500 : 300,
                        }}
                      >
                        Empresas
                      </Typography>
                    </Box>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={isBusiness ? doctors : users}
                      disableCloseOnSelect
                      value={isBusiness ? selectedBusinessOption : selectedDoctorOption}
                      onChange={(event: any, newValue: any) => {
                        if (isBusiness) {
                          setDoctorsSolicited(newValue.map((item: { id: string }) => item.id))
                        } else {
                          setSelectedDoctor(newValue.map((item: { id: string }) => item.id))
                        }
                      }}
                      getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.attributes.name}
                        </li>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label={isBusiness ? 'Empresas' : 'Médicos'}
                          placeholder={isBusiness ? 'Empresas' : 'Médicos'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Box marginTop={'20px'} justifyContent={'flex-end'} display={'flex'}>
                <Button
                  onClick={() => {
                    handleClose()
                    setDoctorsSolicited(null)
                    setSelectedDoctor(null)
                  }}
                  sx={{ mr: 2, color: '#4B4A64' }}
                >
                  Fechar
                </Button>
                {isCreate ? (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || !acceptedFile || !selectedDoctor || !date || !formik.isValid}
                    sx={{ color: 'white', backgroundColor: '#009D81' }}
                  >
                    {loading ? 'Enviando...' : 'Enviar'}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleSend()
                    }}
                    variant="contained"
                    disabled={loading}
                    sx={{ color: 'white', backgroundColor: '#009D81' }}
                  >
                    {loading ? 'Enviando...' : 'Enviar'}
                  </Button>
                )}
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}
