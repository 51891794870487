import { CSSProperties } from 'react'

export interface Transaction {
  amount_cents: number
  amount_currency: string
  crawler_execution_id: number
  crawler_execution_info: any[]
  crawler_updated_at: string
  created_at: string
  data_source_entity_id: number
  data_source_id: number
  data_source_request_id: number
  data_source_user_id: number
  due_date: string | null
  encrypted_patient: string
  encrypted_patient_iv: string
  id: number
  insurance_id: number
  last_crawler_execution_id: number
  metadata: {
    individual_received: any // Define the shape here if known
  }
  outdated: boolean
  parsing_identifier: string
  patient: string
  patient_hash: string
  patient_hash_fixed: boolean
  payment_date: string
  performing_date: string
  person_type: string
  procedure_id: number
  processing_date: string | null
  published: boolean
  removed: boolean
  source_identifier: string | null
  source_identifier_corporate_received: string | null
  source_identifier_disallowance: string | null
  source_identifier_individual_received: string | null
  source_identifier_receivable: string | null
  status: string
  token: string
  updated_at: string
  user_id: number
}

export interface Attributes {
  disallowance: string | null
  insurance_name: string
  insurance_slug: string
  original_procedure_id: string | null
  patient: string
  performing_date: string
  procedure_hashid: string
  procedure_id_changed: string | null
  procedure_id_changed_name: string | null
  procedure_name: string
  processing_date: string
  secondary_status: string
  status: string
  transaction: Transaction
}
export interface DocumentsTableProps {
  data: Attributes[]
  isCom: boolean
  loading: boolean
  onFilterChange: (filters: any) => void
  count: number
}

export const override: CSSProperties = {
  display: 'block',
  margin: 'auto auto',
}
