export const calculateRatings = (data: any) => {
  let belowFive = 0
  let fiveToSeven = 0
  let aboveSeven = 0

  for (let i = 0; i < data.length; i++) {
    const rating = data[i].attributes.rating
    if (rating < 7) {
      belowFive++
    } else if (rating >= 7 && rating <= 8) {
      fiveToSeven++
    } else if (rating > 8) {
      aboveSeven++
    }
  }

  return [belowFive, fiveToSeven, aboveSeven]
}
