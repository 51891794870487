import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid } from '@mui/material'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { style } from './styled'

export default function ModalDetail({
  handleClose,
  open,
  data,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  open: boolean
  data: any
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={isMobile ? '70%' : '50%'}>
        <Typography variant="h5" component="h2">
          Detalhes do responsavel
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Dados Pessoais
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              Razão Social
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.name}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              CNPJ
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.cpf_cnpj || 'Sem dados'}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              Telefone
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.phone || 'Sem dados'}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              Endereço
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.address || 'Sem dados'}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              E-mail
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.email || 'Sem dados'}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              Registro Estadual
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.state_registration || 'Sem dados'}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, color: '#10192D' }}>
              Registro Municipal
            </Typography>
            <Typography variant="subtitle2">{data.attributes?.municipal_registration || 'Sem dados'}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Dados Bancários
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Conta
            </Typography>
            <Typography variant="subtitle2">Sem dados</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Agência
            </Typography>
            <Typography variant="subtitle2">Sem dados</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Banco
            </Typography>
            <Typography variant="subtitle2">Sem dados</Typography>
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button onClick={handleClose} sx={{ mr: 2, color: '#4B4A64' }}>
            Fechar
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
