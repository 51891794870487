import { ApiVersion, BaseService } from '~services/api'

export const GetAllCompanies = (
  Uid: string,
  access: string,
  client: string,
  pageInfo: { per_page: string; page: string },
) => {
  return BaseService.get(`/institution_api/v1/companies/?per_page=${pageInfo.per_page}&page=${pageInfo.page}`, {
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const EditCompany = (Uid: string, access: string, client: string, id: string, data: any) => {
  return BaseService.put(
    `/institution_api/v1/companies/${id}`,
    {
      company: data,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}
