import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { BootstrapInput } from 'pages/ConversationsPage/styled'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormik } from 'formik'
import { useUserStore } from 'stores/users'
import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo/DemoContainer'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'
import 'dayjs/locale/pt-br'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { GetProcedures, OpenNewConversation, SendConversationMessage } from '~services/Conversations'
import { validationSchema } from 'schemas'
import { CustomInput } from '../modalEditPush/styled'
import { style } from './styled'

export default function ModalNewComunicacao({
  handleClose,
  open,
  doctors,
}: {
  handleClose: any
  open: boolean
  doctors: any
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const Uid = useUserStore((state) => state.phone)
  const id = useUserStore((state) => state.id)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [message, setMessage] = React.useState('')
  const [procedimentos, setProcedimentos] = React.useState<any>()
  const [selectedDoctor, setSelectedDoctor] = React.useState('')
  const [selectedData, setSelectedData] = React.useState('')
  const [selectedProcedimento, setSelectedProcedimento] = React.useState<any>()

  const formik = useFormik({
    initialValues: {
      email: 'teste@gmail.com',
      password: '123456',
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      const data = {
        institution_user_hashid: id,
        user_hashid: selectedProcedimento.attributes.user_hashid,
        medical_production_hashid: selectedProcedimento.id,
      }
      OpenNewConversation(Uid, access, client, data)
        .then((response) => {
          SendConversationMessage(response.data.data.id, Uid, access, client, message)
            .then(() => {
              toast.success('Mensagem enviada com sucesso!')
              setTimeout(() => window.location.reload(), 3000)
            })
            .catch(() => {
              toast.error('Ooops, algo deu errado! Tente novamente.')
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  })

  const searchProcedures = (e) => {
    GetProcedures(Uid, access, client, selectedDoctor, e)
      .then((response) => {
        setProcedimentos(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box sx={style} width={isMobile ? '80%' : '50%'}>
            <Typography variant="h5" component="h2">
              Iniciar Conversa
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2" sx={{ mt: 2, color: '#696969' }}>
                  Crie uma inconformidade encontrada em alguma transação.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid item ml={4} mt={1}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        options={doctors}
                        onChange={(event: any, newValue: any) => {
                          setSelectedDoctor(newValue.id)
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.attributes.name}
                          </li>
                        )}
                        style={{ width: 230 }}
                        renderInput={(params: any) => <TextField {...params} label="Médicos" placeholder="Médicos" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item ml={4} mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          label="Data de realização"
                          format="DD/MM/YYYY"
                          defaultValue={dayjs()}
                          onChange={(e) => {
                            setSelectedData(dayjs(e).format('YYYY-MM-DD'))
                            searchProcedures(dayjs(e).format('YYYY-MM-DD'))
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container justifyContent={'flex-start'} mt={2} flexDirection={'row'}>
                  <Grid item ml={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-simple-select-label">Procedimento</InputLabel>
                      <Select
                        sx={{ width: '330px' }}
                        id="demo-customized-select-native"
                        input={<BootstrapInput />}
                        onChange={(e) => {
                          setSelectedProcedimento(e.target.value)
                        }}
                      >
                        {procedimentos?.map((item) => (
                          <MenuItem sx={{ pl: '10px' }} value={item}>
                            {item.attributes.patient + ' - ' + item.attributes.procedure_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <CustomInput
                  fullWidth
                  placeholder="Mensagem"
                  multiline
                  sx={{ mt: 2 }}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '20px',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Button
                onClick={() => {
                  handleClose()
                  setSelectedDoctor('')
                  setSelectedData('')
                  setSelectedProcedimento('')
                  setMessage('')
                }}
                sx={{ mr: 1, color: '#984A4A' }}
              >
                Cancelar
              </Button>
              <Button type="submit" sx={{ mr: 3, color: '#009D81' }}>
                Registrar
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </>
  )
}
