import { Container, Typography, Divider } from '@mui/material'
import { motion } from 'framer-motion'
import LoginForm from '../../../components/auth/LoginForm'
import Logo from '~components/logo'
import { RootStyle, ContentStyle, HeadingStyle, fadeInUp } from './styled'

export const Login = () => {
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo width={200} />
            <Typography my={5} sx={{ color: 'text.secondary' }}>
              Entre na sua conta
            </Typography>
          </HeadingStyle>
          <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp} />
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
